import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { Button, Drawer, Form, Modal } from 'antd';
import Icon from '@ant-design/icons';

import { userTokenVar, restoreUserToken } from '../../graphql/cache';

import { IconArrowLeft } from '../../assets';
import './style.less';
import { Devices } from '../../assets';
// import { Redirect } from "react-router-dom";
import { TassAppFullLogo } from '../../assets';

export default function PaymentModal(props) {
  const { visible, setVisible } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (isMobile) {
    return (
      <Drawer
        visible={visible}
        closable={false}
        placement="bottom"
        width="100%"
        height="calc(100% - 48px)"
        className="appraisal-drawer"
        zIndex={9}
      >
        <Button
          type="link"
          icon={<Icon component={IconArrowLeft} />}
          size="small"
          onClick={handleCancel}
          className="btn-go-back"
        >
          Volver
        </Button>
        <AppraisalFormContent {...props} />
      </Drawer>
    );
  }

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width="80%"
      height="90vh"
      className="appraisal-modal"
      zIndex={1000}
    >
      <AppraisalFormContent {...props} />
    </Modal>
  );
}

PaymentModal.propTypes = {
  propertyType: PropTypes.string,
  setPropertyType: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setModalAuthType: PropTypes.func,
};

const AppraisalFormContent = (props) => {
  const { propertyType } = props;

  const userToken = useReactiveVar(userTokenVar);

  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: 1230 });

  useEffect(() => form.resetFields(), [propertyType]);

  useEffect(() => {
    restoreUserToken();
  }, []);

  useEffect(() => {
    restoreUserToken();
  }, [userToken]);

  const buttonSize = isMobile ? 'middle' : 'large';

  const ValorizationButton = () => (
    <Button size={buttonSize} type="primary">
      VALORIZACIÓN ÚNICA 1UF
    </Button>
  );
  if (isMobile) {
    return (
      <div className="payment-content">
        <div className="second-container">
          <h2>Desbloquea tu valorización detallada hoy</h2>
          <p>
            Por sólo 1 UF podrás ver, descargar y compartir el detalle completo de la valorización
            de tu propiedad.
          </p>
          <div className="pru">
            <p3>
              <li>Valorización instantánea para valor de venta y arriendo de la propiedad.</li>

              <li>
                Rango de intervalo negociable para precio de publicación y venta de la propiedad.
              </li>

              <li>
                Rango de intervalo negociable para precio de publicación y arriendo de la propiedad.
              </li>

              <li>Porcentaje de rentabilidad anual.</li>

              <li>Rango de rentabilidad anual.</li>

              <li>Propiedades comparables en el mercado.</li>
            </p3>
            <br></br>
            <div className="button">
              <ValorizationButton />
              <br></br>
              <div className="cont2">
                <p4>
                  <br></br>Subscríbete a los planes que tenemos para ti aquí.
                </p4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-content">
      <div className="payment-form">
        <img src={TassAppFullLogo} alt="Tassapp" height="50" />
        <br></br>
        <br></br>
        <div className="image">
          <Devices width={450} />
        </div>

        <p2>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          En TassApp utilizamos un modelo de inteligencia artificial para calcular los intervalos de
          los detalles de las valorizaciones en tiempo real.
          <br></br>
          <br></br>
          <br></br>
          Se obtiene con un intervalo de confianza del 90% de precisión, que se calcula en base a la
          variabilidad de las predicciones de nuestro modelo.
        </p2>
      </div>
      <div className="second-container">
        <h2>Desbloquea tu valorización detallada hoy</h2>
        <p>
          Por sólo 1 UF podrás ver, descargar y compartir el detalle completo de la valorización de
          tu propiedad.
        </p>
        <div className="pru">
          <p3>
            <li>Valorización instantánea para valor de venta y arriendo de la propiedad.</li>

            <li>
              Rango de intervalo negociable para precio de publicación y venta de la propiedad.
            </li>

            <li>
              Rango de intervalo negociable para precio de publicación y arriendo de la propiedad.
            </li>

            <li>Porcentaje de rentabilidad anual.</li>

            <li>Rango de rentabilidad anual.</li>

            <li>Propiedades comparables en el mercado.</li>
          </p3>
          <br></br>
          <div className="button">
            <ValorizationButton />
            <br></br>
            <div className="cont2">
              <p4>
                <br></br>Subscríbete a los planes que tenemos para ti aquí.
              </p4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AppraisalFormContent.propTypes = PaymentModal.propTypes;
