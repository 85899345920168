import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Button, Input, Modal } from 'antd';
import Icon from '@ant-design/icons';
import { userRoleVar } from '../../graphql/cache';

import { GET_PROPERTIES_FROM_FILTER } from '../../graphql/queries';
//import { HIDE_PUBLICATION } from '../../graphql/mutations';
import PropertiesFilters from './Filters';
import PropertySummary from './Summary';
import CardGrid from './CardGrid';
import Map from '../Map';
import { parsePropertiesAndMarkersData } from './services';
import { filtersInit } from './constants';
import { IconMap, IconList, IconPlusNew, IconReload } from '../../assets';
import './style.less';
import { fakeCollections } from '../../fakeData';

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function Properties() {
  // Hooks
  const history = useHistory();
  const query = useQueryParams();
  const userRole = useReactiveVar(userRoleVar);

  // States
  const [selectedProperties, setSelectedProperties] = useState(new Set());
  const [gridLayout, setGridLayout] = useState('vertical');
  const [resultsInsteadOfMap, setResultsInsteadOfMap] = useState(false);
  const [propertyOnHook, setPropertyOnHook] = useState();
  const [filters, setFilters] = useState(filtersInit(query));
  const [propertyCardsIndex, setPropertyCardsIndex] = useState({
    index: 1,
    max: 12,
  });
  const [polygonFilter, setPolygonFilter] = useState({
    type: 'Polygon',
    coordinates: [[]],
  });
  const [dataCardState, setDataCardState] = useState();
  const [markersData, setMarkersData] = useState();
  const [collectionModalIsVisible, setCollectionModalIsVisible] = useState(false);
  const [newCollectionModalIsVisible, setNewCollectionModalIsVisible] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [addedCollectionTooltip, setAddedCollectionTooltip] = useState(false);
  const { id } = useParams();
  const propertyId = Number(id);

  // Queries
  const [getPropertiesFromFilter, { data, loading }] = useLazyQuery(GET_PROPERTIES_FROM_FILTER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { parsedPropertiesData, parsedMarkersData } = parsePropertiesAndMarkersData(
        data.getPropertiesFromFilter,
      );
      setDataCardState(parsedPropertiesData);
      setMarkersData(parsedMarkersData);
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(error);
      }
    },
  });

  const cardScrollRef = useRef(null);

  // Effects
  useEffect(() => {
    setDataCardState(null);
    if (cardScrollRef.current) {
      cardScrollRef.current.scrollTo(0, 0);
    }

    setPropertyCardsIndex({
      index: 1,
      max: 12,
    });
    if (polygonFilter.coordinates[0].length > 0) {
      getPropertiesFromFilter({
        variables: {
          property_type: filters.propertyTypeFilter,
          operation_type: filters.operationTypeFilter,
          published_price__min: parseFloat(filters.publishedPriceFilter.minValue),
          published_price__max: parseFloat(filters.publishedPriceFilter.maxValue),
          rooms__min: parseInt(filters.roomsFilter.minValue, 10),
          rooms__max: parseInt(filters.roomsFilter.maxValue, 10),
          bathrooms__min: parseInt(filters.bathroomsFilter.minValue, 10),
          bathrooms__max: parseInt(filters.bathroomsFilter.maxValue, 10),
          covered_area__min: parseFloat(filters.coveredAreaFilter.minValue),
          covered_area__max: parseFloat(filters.coveredAreaFilter.maxValue),
          total_area__min: parseFloat(filters.totalAreaFilter.minValue),
          total_area__max: parseFloat(filters.totalAreaFilter.maxValue),
          polygon: polygonFilter,
        },
      });
    }
  }, [
    polygonFilter,
    filters.propertyTypeFilter,
    filters.operationTypeFilter,
    filters.publishedPriceFilter,
    filters.roomsFilter,
    filters.bathroomsFilter,
    filters.coveredAreaFilter,
    filters.totalAreaFilter,
    filters.sortFilter,
  ]);

  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const forceGrid = useMediaQuery({ maxWidth: 767 });
  const isMobile = useMediaQuery({ maxWidth: 480 });

  useEffect(() => {
    if (forceGrid) {
      setGridLayout('vertical');
    }
  }, [isMobile]);

  const onCardClick = (property) => {
    history.push(`/properties/${property.id}`);
  };

  const handleSelectProperty = (paramId, isSelected) => {
    const newSet = new Set(selectedProperties);
    if (isSelected) {
      newSet.add(paramId);
    } else {
      newSet.delete(paramId);
    }
    setSelectedProperties(newSet);
  };

  if (propertyId && !isDesktop) {
    return <PropertySummary propertyId={propertyId} userRole={userRole} />;
  }
  const showCollectionModal = () => {
    setCollectionModalIsVisible(true);
  };

  const showNewCollectionModal = () => {
    setNewCollectionModalIsVisible(true);
  };

  const handleInputChange = (e) => {
    setNewCollectionName(e.target.value);
  };

  const handleHideSelectedProperties = () => {
    //hidePublication({ variables: 1})
    //Array.from(selectedProperties).map((propiedad) => hidePublication({ variables: propiedad }));
  };

  const handleShowSelectedProperties = () => {
    //Array.from(selectedProperties).map((propiedad) => console.log('show query: ', propiedad));
    //Array.from(selectedProperties).map((propiedad) => hidePublication({ variables: propiedad }));
  };

  const hideMap = isMobile && resultsInsteadOfMap;
  const hideResults = isMobile && !resultsInsteadOfMap;

  return (
    <div className="searchView">
      {isDesktop && (
        <Modal
          visible={propertyId}
          onCancel={() => history.goBack()}
          footer={null}
          closable={false}
          className="property-summary-modal"
          width={960}
          destroyOnClose
          centered
        >
          <PropertySummary propertyId={propertyId} userRole={userRole} />
        </Modal>
      )}

      <Modal
        visible={collectionModalIsVisible && selectedProperties.size > 0}
        onCancel={() => setCollectionModalIsVisible(false)}
        title={[
          <div key="1" className="text">
            Guardar en colección:{' '}
          </div>,
          <div key="2" className="plus-button">
            <Button
              icon={<Icon component={IconPlusNew} />}
              type="link"
              onClick={() => {
                setCollectionModalIsVisible(false);
                showNewCollectionModal();
              }}
            />
          </div>,
        ]}
        footer={[
          <Button
            key="back"
            className="footer-button"
            onClick={() => setCollectionModalIsVisible(false)}
          >
            Cancelar
          </Button>,
        ]}
        closable={false}
        className="collection-modal"
        width={785}
        height={338}
        destroyOnClose
        centered
      >
        <div className="row-display">
          {fakeCollections.map((collection) => {
            return (
              <div key={collection.id} className="display-element">
                <div className="display-element-img">
                  <img
                    src={`${collection.img}`}
                    alt="foto"
                    onClick={() => {
                      setCollectionModalIsVisible(false);
                      setAddedCollectionTooltip({
                        show: true,
                        number: selectedProperties.size,
                        name: collection.name,
                      });
                      setTimeout(
                        () => setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false }),
                        7000,
                      );
                    }}
                  />
                </div>
                <div className="text">{collection.name}</div>
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        visible={newCollectionModalIsVisible}
        onCancel={() => setNewCollectionModalIsVisible(false)}
        title={[
          <div key="1" className="text">
            Nueva colección
          </div>,
        ]}
        footer={[
          <div key="1" className="footer-button">
            {newCollectionName === '' ? (
              <Button
                className="footer-button"
                key="back"
                onClick={() => setNewCollectionModalIsVisible(false)}
              >
                Cancelar
              </Button>
            ) : (
              <Button
                className="footer-button"
                key="submit"
                onClick={() => {
                  const name = newCollectionName;
                  setNewCollectionName('');
                  setNewCollectionModalIsVisible(false);
                  setAddedCollectionTooltip({
                    show: true,
                    number: selectedProperties.size,
                    name: name,
                  });
                  setTimeout(
                    () => setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false }),
                    7000,
                  );
                }}
              >
                Listo
              </Button>
            )}
          </div>,
        ]}
        closable={false}
        className="collection-modal"
        width={785}
        height={334}
        destroyOnClose
        centered
      >
        <div className="new-collection-body">
          <div className="display-element">
            <div className="display-element-img">
              <img src={`${fakeCollections[0].img}`} alt="foto" />
            </div>
            <div className="text">
              <Input className="new-input" bordered={false} onChange={handleInputChange} />
            </div>
          </div>
        </div>
      </Modal>

      {addedCollectionTooltip.show && (
        <div className="add-collections-popup">
          <div className="add-popup-text">
            {`${addedCollectionTooltip.number} propiedades han sido guardadas en la colección "${addedCollectionTooltip.name}"`}
          </div>
          <div className="add-popup-undo" onClick={() => {}}>
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </div>
        </div>
      )}

      <PropertiesFilters
        filters={filters}
        setFilters={setFilters}
        dataCardState={dataCardState}
        resultsInsteadOfMap={resultsInsteadOfMap}
        markersLoading={loading}
        setGridLayout={setGridLayout}
        selectedProperties={selectedProperties}
        clearSelectedProperties={() => setSelectedProperties(new Set())}
        showCollectionsModal={showCollectionModal}
        hideSelectedProperties={handleHideSelectedProperties}
        showSelectedProperties={handleShowSelectedProperties}
        userRole={userRole}
      />

      <CardGrid
        loadingMore={loading}
        gridLayout={gridLayout}
        dataCardState={dataCardState}
        onCardClick={onCardClick}
        seeMore={
          data &&
          data.getPropertiesFromFilter.length > propertyCardsIndex.max * propertyCardsIndex.index
        }
        onSeeMoreClick={() =>
          setPropertyCardsIndex({
            max: propertyCardsIndex.max,
            index: propertyCardsIndex.index + 1,
          })
        }
        setPropertyOnHook={setPropertyOnHook}
        handleSelectProperty={handleSelectProperty}
        selectedProperties={selectedProperties}
        hidden={hideResults}
      />

      <div className={`mapContainer ${hideMap && 'hidden'}`}>
        <div className="map">
          <Map
            forcePopup={propertyOnHook}
            onCardClick={onCardClick}
            address={
              query.get('searchAddress') !== 'undefined' && query.get('searchAddress') !== null
                ? {
                    value: query.get('searchAddress'),
                    coords: {
                      latitude: parseFloat(query.get('latitude')),
                      longitude: parseFloat(query.get('longitude')),
                    },
                  }
                : undefined
            }
            markersData={markersData}
            isLoadingMarkers={loading}
            dataCard={dataCardState}
            callback={(polygon) => {
              for (let i = 0; i < polygon.coordinates[0].length; i += 1) {
                if (polygonFilter.coordinates[0][i]) {
                  if (
                    polygon.coordinates[0][i][0] !== polygonFilter.coordinates[0][i][0] ||
                    polygon.coordinates[0][i][1] !== polygonFilter.coordinates[0][i][1]
                  ) {
                    setPolygonFilter(polygon);
                    break;
                  }
                } else {
                  setPolygonFilter(polygon);
                  break;
                }
              }
            }}
            handleSelectProperty={handleSelectProperty}
            selectedProperties={selectedProperties}
          />
        </div>
      </div>

      {isMobile && (
        <Button
          className="mapOrGridButton"
          onClick={() => setResultsInsteadOfMap(!resultsInsteadOfMap)}
          type="primary"
          shape="round"
          icon={resultsInsteadOfMap ? <IconMap /> : <IconList />}
        >
          {resultsInsteadOfMap ? 'Mapa' : 'Listado'}
        </Button>
      )}
    </div>
  );
}
