import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button, Tooltip, Checkbox } from 'antd';
import _ from 'lodash';
import Icon from '@ant-design/icons';
import PropertiesExcelDownload from '../Properties/ExcelDownload';
import { IconClose, IconTrash, IconDownload, IconReload } from '../../assets';
import { fakeCollections } from '../../fakeData';
import Constants from '../../constants';
import { useHistory } from 'react-router-dom';

const { IS_DESKTOP, IS_IPAD_PRO, IS_MOBILE } = Constants;

export const UserProfileCollections = () => {
  const [selectedCollections, setSelectedCollections] = useState(new Set());
  const [collections, setCollections] = useState(fakeCollections);
  const [deletedTooltip, setDeletedTooltip] = useState(false);

  const isDesktop = useMediaQuery(IS_DESKTOP);
  const isLargeMobile = useMediaQuery(IS_IPAD_PRO);
  const isMobile = useMediaQuery(IS_MOBILE);

  const history = useHistory();

  const handleSelectedCollections = (id, isSelected) => {
    const newSet = new Set(selectedCollections);
    if (isSelected) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedCollections(newSet);
  };

  const resetSelectedCollections = () => {
    setSelectedCollections(new Set());
  };

  const deleteSelectedCollections = () => {
    // Esto después se tiene que hacer en el onCompleted
    // de la mutación que elimina colecciones
    const newCollections = collections.filter(
      (colection) => !selectedCollections.has(colection.id),
    );
    const deleted = _.differenceBy(collections, newCollections, 'id');
    setCollections(newCollections);
    setSelectedCollections(new Set());
    setDeletedTooltip({ show: true, deleted });
    setTimeout(() => setDeletedTooltip({ ...deletedTooltip, show: false }), 7000);
  };

  const restoreCollections = () => {
    const newCollections = [...collections, ...deletedTooltip.deleted];
    setDeletedTooltip({ ...deletedTooltip, show: false });
    setCollections(newCollections);
  };

  const handleCollectionClick = (id) => {
    let path = `/user/collection/${id}`;
    history.push(path);
  };

  return (
    <div className="collections-data-view">
      {isMobile && (
        <div className="collections-tag">
          <div className="text">Mis colecciones</div>
        </div>
      )}
      <div className="collections-view-header">
        <div className=" collections-view-header-count">
          {isDesktop
            ? `${collections.length} colecciones guardadas`
            : `${collections.length} colecciones`}
        </div>
        <div className="collections-view-header-options">
          <div className="collections-view-header-text">
            {isDesktop
              ? `${selectedCollections.size} colecciones seleccionadas`
              : `${selectedCollections.size} seleccionadas`}
          </div>
          <div className="actions">
            <span className="collections-header-tools-first">
              <PropertiesExcelDownload
                render={
                  <Tooltip title="Descargar Excel">
                    <Button
                      icon={<Icon component={IconDownload} />}
                      type="link"
                      onClick={() => {}}
                    />
                  </Tooltip>
                }
                filename="TassApp Resumen de Propiedades"
                sheetName="Hoja 1"
                data={[]}
              ></PropertiesExcelDownload>
            </span>
            <span className="collections-header-tools">
              <Tooltip title="Eliminar colección">
                <Button
                  icon={<Icon component={IconTrash} />}
                  type="link"
                  onClick={() => deleteSelectedCollections()}
                />
              </Tooltip>
            </span>
            <span className="collections-header-tools">
              <Tooltip title="Deseleccionar">
                <Button
                  icon={<Icon component={IconClose} />}
                  type="link"
                  onClick={() => resetSelectedCollections()}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      <div className="collections-display">
        {collections.map((elemento) => {
          return (
            <div className="collections-display-element" key={elemento.id}>
              <div className="collections-display-element-img">
                <div className="img-icons">
                  <Checkbox
                    style={{
                      borderColor: '#FFFFF',
                      backgroundColor: '#FFFFF',
                      opacity: 0.76,
                    }}
                    onChange={(e) => handleSelectedCollections(elemento.id, e.target.checked)}
                    onClick={(e) => e.stopPropagation()}
                    checked={selectedCollections.has(elemento.id)}
                  />
                </div>
                <img
                  src={`${elemento.img}`}
                  alt="foto"
                  onClick={() => handleCollectionClick(elemento.id)}
                />
              </div>
              <div className="text">{elemento.name}</div>
            </div>
          );
        })}
      </div>
      {(isMobile || isLargeMobile) && deletedTooltip.show && (
        <div className="delete-collections-popup">
          <div className="delete-popup-text">
            {`${deletedTooltip.deleted.length} colecciones han sido eliminadas exitosamente`}
          </div>
          <div className="delete-popup-undo" onClick={() => restoreCollections()}>
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileCollections;
