import React from "react";
import ReactDOM from "react-dom";
import { cache } from "./graphql/cache";
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { userTokenVar } from "./graphql/cache";
import App from "./App";
import "./index.css";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URI,
});

const tokenLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("@userToken");
  const refresh = localStorage.getItem("@refreshToken");

  if (token && refresh) {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        "x-token": token ? `${token}` : "",
        "x-refresh-token": refresh ? `${refresh}` : "",
      },
    }));
  }

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const xToken = headers.get("x-token");
      const xRefreshToken = headers.get("x-refresh-token");
      if (xToken) {
        userTokenVar(xToken);
      }
      if (xRefreshToken) {
        window.localStorage.setItem("@refreshToken", xRefreshToken);
      }
    }

    return response;
  });
});

const redirectLink = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach((error) => {
    if (error.extensions.code === "INTERNAL_SERVER_ERROR") {
      document.location = "/500";
    } else if (error.extensions.code === "FORBIDDEN") {
      console.log(error);
      document.location = "/404";
    } else if (error.extensions.code === "INVALID_TOKEN") {
      document.location = "/";
    }
  });
});

const resetToken = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach((error) => {
    if (["INVALID_TOKEN", "BAD_USER_INPUT"].includes(error.extensions.code)) {
      window.localStorage.clear();
    }
  });
});

const client = new ApolloClient({
  cache: cache,
  link: from([redirectLink, resetToken, tokenLink, httpLink]),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
