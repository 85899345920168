/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Button, Checkbox, Tooltip } from "antd";
import FileSaver from "file-saver";
import Icon from "@ant-design/icons";
import XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";

import Loading from "../Loading";
import {
  IconClose,
  IconDelete,
  IconDownload,
  IconEyeOpened,
} from "../../assets";
import {
  GET_CURRENT_USER_MASSIVE_VALORIZATIONS,
  GET_MASSIVE_VALORIZATION_DETAILS,
} from "../../graphql/queries";
moment.locale("es");

export default function MassiveAppraisals() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [massiveValorizationsData, setMassiveAppraisalsData] = useState([]);
  const [
    isDataOfMassiveApraissalReady,
    setIsDataOfMassiveApraissalReady,
  ] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [actualId, setActualId] = useState();
  const [
    paginationIndexForExcelData,
    setPaginationIndexForExcelData,
  ] = useState(0);
  const [selected, setSelected] = useState([]);
  const [countProperties, setCountProperties] = useState(0);
  const [downloadingMultipleData, setDownloadingMultipleData] = useState(false);
  const [fulldataForExcel, setFulldataForExcel] = useState([]);
  const [multiDataForExcel, setMultiDataForExcel] = useState([]);

  //querys

  const { loading } = useQuery(GET_CURRENT_USER_MASSIVE_VALORIZATIONS, {
    fetchPolicy: "network-only",
    variables: {
      limit: 11,
      offset: paginationIndex * 10,
    },
    onCompleted: (data) => {
      if (paginationIndex > 0) {
        setMassiveAppraisalsData([
          ...massiveValorizationsData,
          ...data.getMyMassiveValorizations.slice(1),
        ]);
      } else {
        setMassiveAppraisalsData([
          ...massiveValorizationsData,
          ...data.getMyMassiveValorizations,
        ]);
      }
    },
  });

  const [
    getDataForExcel,
    { loading: loadingDataForExcel, data: dataForExcel },
  ] = useLazyQuery(GET_MASSIVE_VALORIZATION_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log(data);
      }
      if (data.getMassiveValorizationDetail.length < 100) {
        setIsDataOfMassiveApraissalReady(true);
        if (!downloadingMultipleData) {
          createExcel([
            ...fulldataForExcel,
            ...data.getMassiveValorizationDetail,
          ]);
        } else {
          setActualId(selected[selected.indexOf(actualId) + 1]);
        }
      } else {
        setFulldataForExcel([
          ...fulldataForExcel,
          ...data.getMassiveValorizationDetail,
        ]);
        setPaginationIndexForExcelData(paginationIndexForExcelData + 1);
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === "development") {
        if (graphQLErrors) {
          console.log("getCurrentUser", graphQLErrors);
        }
        if (networkError) {
          console.log("getCurrentUser", networkError);
        }
      }
    },
  });

  //functions

  const DownloadExcel = (id) => {
    if (typeof id === "number") {
      setActualId(id);
      setIsDataOfMassiveApraissalReady(false);
      getDataForExcel({
        variables: {
          limit: 100,
          offset: 0,
          id: id,
        },
      });
    } else if (Array.isArray(id) && id.length > 0) {
      setActualId(id[0]);
      setIsDataOfMassiveApraissalReady(false);
      setDownloadingMultipleData(true);
      getDataForExcel({
        variables: {
          limit: 100,
          offset: 0,
          id: id[0],
        },
      });
    }
  };

  const changeSelect = (id) => {
    if (selected.includes(id)) {
      const index = selected.indexOf(id);
      if (index > -1) {
        const newSelected = [...selected];
        newSelected.splice(index, 1);

        setSelected(newSelected);
      }
    } else {
      setSelected([...selected, id]);
    }
  };

  function createExcel(data) {
    if (process.env.NODE_ENV === "development") {
      console.log("create excel :", data);
    }
    const csvData = data;
    const wscols = [
      {
        wch: Math.max("id".length, ...data.map((d) => d.id.toString().length)),
      },
      {
        wch: Math.max(
          "Latitud".length,
          ...data.map((d) => d.latitude.toString().length)
        ),
      },
      {
        wch: Math.max(
          "Longitud".length,
          ...data.map((d) => d.longitude.toString().length)
        ),
      },
      {
        wch: Math.max(
          "Dirección de propiedad".length,
          ...data.map((d) => d.address.length)
        ),
      },
      {
        wch: Math.max(
          "Tipo de propiedad".length,
          ...data.map((d) => d.propertyType.length)
        ),
      },
      {
        wch: Math.max(
          "Valorización de venta".length,
          ...data.map((d) => d.sellPrice.toString().length)
        ),
      },
      {
        wch: Math.max(
          "Valorizacion de arriendo".length,
          ...data.map((d) => d.rentPrice.toString().length)
        ),
      },
      {
        wch: Math.max(...data.map((d) => d.address.length)),
      },
    ];

    const fileName = `valorizacionMasiva-1.xlsx`;
    const newArray = csvData.map((data) => {
      let { __typename, ...fixedData } = data;
      return fixedData;
    });
    const ws = XLSX.utils.json_to_sheet(
      [
        {
          id: "ID",
          latitude: "Latitud",
          longitude: "Longitud",
          address: "Dirección de propiedad",
          propertyType: "Tipo de propiedad",
          sellPrice: "Valorización de venta",
          rentPrice: "Valorizacion de arriendo",
        },
      ],
      {
        header: [
          "id",
          "latitude",
          "longitude",
          "address",
          "propertyType",
          "sellPrice",
          "rentPrice",
        ],
        skipHeader: true,
        origin: 0, //ok
      }
    );
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, newArray, {
      header: [
        "id",
        "latitude",
        "longitude",
        "address",
        "propertyType",
        "sellPrice",
        "rentPrice",
      ],
      skipHeader: true,
      origin: -1, //ok
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const da = new Blob([excelBuffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(da, fileName + ".xlsx");
    setFulldataForExcel([]);
    setActualId();
  }

  //useEffect

  useEffect(() => {
    var count = 0;
    selected.map((x) => {
      massiveValorizationsData.map((massiveValorization) => {
        if (massiveValorization.id === x) {
          count += massiveValorization.valorizationCount;
        }
      });
    });
    setCountProperties(count);
  }, [selected]);

  useEffect(() => {
    if (paginationIndexForExcelData > 0) {
      getDataForExcel({
        variables: {
          limit: 100,
          offset: paginationIndexForExcelData * 100,
          id: actualId,
        },
      });
    }
  }, [paginationIndexForExcelData]);

  useEffect(() => {
    if (downloadingMultipleData && !loadingDataForExcel) {
      if (selected.length > multiDataForExcel.length) {
        setIsDataOfMassiveApraissalReady(false);
        getDataForExcel({
          variables: {
            limit: 100,
            offset: 0,
            id: selected[multiDataForExcel.length],
          },
        });
      } else {
        const dataForExcel = [].concat(
          ...multiDataForExcel.map((data) => {
            return data.getMassiveValorizationDetail;
          })
        );
        createExcel(dataForExcel);
        setMultiDataForExcel([]);
        setDownloadingMultipleData(false);
      }
    }
  }, [multiDataForExcel]);

  useEffect(() => {
    if (isDataOfMassiveApraissalReady && downloadingMultipleData) {
      if (paginationIndexForExcelData === 0) {
        setMultiDataForExcel([...multiDataForExcel, dataForExcel]);
      } else {
        setPaginationIndexForExcelData(0);
        setMultiDataForExcel([
          ...multiDataForExcel,
          {
            ["getMassiveValorizationDetail"]: [
              ...fulldataForExcel,
              ...dataForExcel.getMassiveValorizationDetail,
            ],
          },
        ]);
      }
    }
  }, [isDataOfMassiveApraissalReady]);

  const buttonSize = isMobile ? "small" : "middle";

  return loading && massiveValorizationsData.length === 0 ? (
    <Loading />
  ) : (
    massiveValorizationsData.length > 0 && (
      <div className="user-profile-appraisals-view massive">
        <div className="massive-appraisals-select-menu">
          <span>
            {selected.length}{" "}
            {isMobile
              ? "valorizaciones"
              : "valorizaciones masivas seleccionadas"}
          </span>
          <span>{countProperties} propiedades seleccionadas</span>

          <div className="actions">
            <Tooltip title="Descargar Excels seleccionados">
              <Button
                onClick={() => DownloadExcel(selected)}
                icon={<Icon component={IconDownload} />}
                size={buttonSize}
              />
            </Tooltip>
            <Tooltip title="Eliminar valorizaciones seleccionadas">
              <Button
                icon={<Icon component={IconDelete} />}
                size={buttonSize}
              />
            </Tooltip>
            <Tooltip title="Limpiar selección">
              <Button
                loading={downloadingMultipleData}
                onClick={() => {
                  setSelected([]);
                }}
                size={buttonSize}
                icon={<Icon component={IconClose} />}
              />
            </Tooltip>
          </div>
        </div>

        <table className="appraisals-table massive">
          <tbody>
            {massiveValorizationsData
              .slice(0, (paginationIndex + 1) * 10)
              .map((x) => (
                <tr key={x.id}>
                  <td className="checkbox-cell">
                    <Checkbox onChange={() => changeSelect(x.id)} />
                  </td>
                  <td className="text-align-left">{`"${x.name}"`}</td>
                  <td>{moment(new Date(x.createdAt)).format("DD/MM/YYYY")}</td>
                  <td className="property-count">{`${x.valorizationCount} propiedades`}</td>
                  <td className="actions-cell">
                    <Tooltip title="Ver detalle de valorización">
                      <Link to={`/massive-appraisals/${x.id}`}>
                        <Button
                          icon={<Icon component={IconEyeOpened} />}
                          size={buttonSize}
                        ></Button>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Descargar Excel">
                      <Button
                        onClick={
                          x.id !== actualId && (() => DownloadExcel(x.id))
                        }
                        icon={<Icon component={IconDownload} />}
                        size={buttonSize}
                      ></Button>
                    </Tooltip>
                    <Tooltip title="Borrar valorización">
                      <Button
                        className={
                          x.id === actualId &&
                          !downloadingMultipleData &&
                          "spin"
                        }
                        icon={<Icon component={IconDelete} />}
                        size={buttonSize}
                      ></Button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {massiveValorizationsData.length > (paginationIndex + 1) * 10 && (
          <Button
            className="btn-see-more"
            onClick={() => {
              setPaginationIndex(paginationIndex + 1);
            }}
          >
            Ver más
          </Button>
        )}
      </div>
    )
  );
}
