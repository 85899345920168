import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import Card from '../Cards/Card';
import SkeletonCard from '../Cards/SkeletonCard';

export default function CardGrid(props) {
  const {
    loadingMore,
    gridLayout,
    dataCardState,
    onCardClick,
    seeMore,
    onSeeMoreClick,
    setPropertyOnHook,
    handleSelectProperty,
    selectedProperties,
    hidden,
    isBlocked,
  } = props;

  return (
    <div className={`resultsScrollable ${hidden && 'hidden'}`}>
      <div className={`searchResultsGrid ${gridLayout}`}>
        {dataCardState
          ? dataCardState.map((cardData) => (
              <Card
                property={cardData}
                layout={gridLayout}
                key={`property-${cardData.id}`}
                onClick={() => onCardClick(cardData)}
                onMouseEnter={() => setPropertyOnHook(cardData.id)}
                onMouseLeave={() => setPropertyOnHook()}
                handleSelectProperty={handleSelectProperty}
                isSelected={selectedProperties.has(cardData.id)}
                isBlocked={isBlocked}
              />
            ))
          : [...Array(12)].map((_, idx) => (
              <SkeletonCard layout={gridLayout} key={`skeleton-${idx}`} />
            ))}
        {dataCardState && !dataCardState.length && (
          <span className="no-results">No se encontraron resultados</span>
        )}
      </div>

      {seeMore && (
        <div className="seeMoreBtnContainer">
          <Button className="seeMoreBtn" onClick={onSeeMoreClick} loading={loadingMore}>
            Ver más
          </Button>
        </div>
      )}
    </div>
  );
}

CardGrid.propTypes = {
  loadingMore: PropTypes.bool,
  gridLayout: PropTypes.string,
  dataCardState: PropTypes.array,
  onCardClick: PropTypes.func,
  seeMore: PropTypes.bool,
  onSeeMoreClick: PropTypes.func,
  setPropertyOnHook: PropTypes.func,
  handleSelectProperty: PropTypes.func,
  selectedProperties: PropTypes.object,
  hidden: PropTypes.bool,
  isBlocked: PropTypes.bool,
};

CardGrid.defaultProps = {
  isBlocked: false,
};
