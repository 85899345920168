/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import { Button } from "antd";
import Icon from "@ant-design/icons";
import ReactExport from "react-export-excel";
import moment from "moment";
import "moment/locale/es";

import {
  GET_MASSIVE_VALORIZATION_DETAILS,
  GET_MASSIVE_VALORIZATION,
} from "../../graphql/queries";
import AppraisalsList from "../Appraisal/List";
import { IconArrowLeft, IconDownload } from "../../assets";
import "./summary.less";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

moment.locale("es");

export default function MassiveAppraisalSummary() {
  const { id } = useParams();
  const appraisalId = parseInt(id);

  const history = useHistory();
  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [appraisals, setAppraisals] = useState([]);
  const [appraisalsIndex, setAppraisalsIndex] = useState(0);
  const {
    loading: loadingGeneralData,
    data: generalData,
  } = useQuery(GET_MASSIVE_VALORIZATION, { variables: { id: appraisalId } });

  const { loading } = useQuery(GET_MASSIVE_VALORIZATION_DETAILS, {
    variables: {
      id: appraisalId,
      limit: 11,
      offset: appraisalsIndex * 10,
    },
    onCompleted: (data) => {
      if (appraisalsIndex > 0) {
        setAppraisals([
          ...appraisals,
          ...data.getMassiveValorizationDetail.slice(1),
        ]);
      } else {
        setAppraisals([...appraisals, ...data.getMassiveValorizationDetail]);
      }
    },
  });

  const buttonSize = isTablet ? "middle" : "large";

  const DownloadExcel = () => {
    return (
      <ExcelFile
        filename={`massive-appraisal${appraisalId}`}
        element={
          <Button
            type="primary"
            icon={<Icon component={IconDownload} />}
            className="btn-download"
            size={buttonSize}
          >
            {isTablet
              ? "Descargar todos los resultados"
              : "Descargar todos los resultados en excel"}
          </Button>
        }
      >
        <ExcelSheet data={appraisals} name="valorizations">
          <ExcelColumn label="ID" value="id" />
          <ExcelColumn label="Latitud" value="latitude" />
          <ExcelColumn label="Longitud" value="longitude" />
          <ExcelColumn label="Dirección de propiedad" value="address" />
          <ExcelColumn label="Tipo de propiedad" value="propertyType" />
          <ExcelColumn label="Valorización de venta" value="sellPrice" />
          <ExcelColumn label="Valorización de arriendo" value="rentPrice" />
        </ExcelSheet>
      </ExcelFile>
    );
  };

  const formattedAppraisalDate = moment(
    new Date(generalData?.getMassiveValorization?.createdAt)
  ).format("DD/MM/YYYY");

  const hasMoreData = (appraisalsIndex + 1) * 10 < appraisals.length;
  const handleSeeMoreClick = () => setAppraisalsIndex(appraisalsIndex + 1);

  return (
    appraisals.length > 0 &&
    !loadingGeneralData && (
      <div className="massive-appraisal-summary-view">
        <div className="header">
          <Button
            icon={<Icon component={IconArrowLeft} />}
            type="link"
            onClick={() => history.goBack()}
            className="btn-go-back"
          />
          <h1>{`"${generalData.getMassiveValorization.name}"`}</h1>
          <span className="header-info">{formattedAppraisalDate}</span>
          {!isMobile && (
            <Fragment>
              <span className="header-info">
                {appraisals.length} propiedades
              </span>
              <DownloadExcel />
            </Fragment>
          )}
        </div>

        <div className="sub-header">
          <span className="header-info">{appraisals.length} propiedades</span>
          <DownloadExcel />
        </div>

        <AppraisalsList
          appraisals={appraisals}
          hasMoreData={hasMoreData}
          handleSeeMoreClick={handleSeeMoreClick}
        />
      </div>
    )
  );
}
