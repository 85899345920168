export const sectorActionValues = [
  {
    label: 'Comuna',
    value: 'comuna',
  },
  {
    label: 'Distrito',
    value: 'distrito',
  },
  {
    label: 'Barrio',
    value: 'barrio',
  },
  {
    label: 'Manzana',
    value: 'manzana',
  },
];

export const propertyTipeActionValues = [
  {
    label: 'Casa',
    value: 'casa',
  },
  {
    label: 'Departamento',
    value: 'departamento',
  },
];
