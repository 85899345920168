import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import SearchDirectionBar from '../SearchDirectionBar';
import { LandingMap } from '../../assets';
import './style.less';

const { Option } = Select;

export default function ValorizationLanding(props) {
  const {
    appraisalFormPropertyType,
    appraisalFormAddress,
    setAppraisalFormPropertyType,
    setAppraisalFormAddress,
    setAppraisalFormVisible,
  } = props;

  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const buttonSize = isDesktop ? 'large' : 'medium';

  const ValorizationButton = () => (
    <Button onClick={() => setAppraisalFormVisible(true)} size={buttonSize} type="primary">
      VALORIZAR
    </Button>
  );

  return (
    <div className="new-landing-container">
      <div className="new-landing-content">
        <div className="new-landing-main">
          <div className="new-landing-main-item">
            <h1>Valoriza tu propiedad hoy</h1>
            <p>Consigue el valor de mercado para venta y arriendo actual en sólo 3 pasos.</p>
            <p2>
              Ingresa los atributos de la propiedad, y obtén un valor recomendado con nuestro modelo
              de inteligencia artificial.
              <br></br>
              Sacale el máximo provecho a tu propiedad.
            </p2>
            <div className="new-button-val">
              <ValorizationButton />
            </div>

            <div className="new-landing-map">
              <LandingMap />
            </div>
          </div>

          <div className="new-landing-box valorization">
            <SearchDirectionBar
              address={appraisalFormAddress}
              setAddress={(address) => {
                setAppraisalFormAddress(address);
              }}
            />
            {!isMobile && (
              <Fragment>
                <Select
                  placeholder="Casa o Departamento"
                  size={buttonSize}
                  onChange={(x) => setAppraisalFormPropertyType(x)}
                  value={appraisalFormPropertyType}
                >
                  <Option value="house">Casa</Option>
                  <Option value="apartment">Departamento</Option>
                </Select>

                <ValorizationButton />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ValorizationLanding.propTypes = {
  appraisalFormPropertyType: PropTypes.string,
  appraisalFormAddress: PropTypes.instanceOf(Object),
  setAppraisalFormPropertyType: PropTypes.func,
  setAppraisalFormAddress: PropTypes.func.isRequired,
  setAppraisalFormVisible: PropTypes.func.isRequired,
};

ValorizationLanding.defaultProps = {
  appraisalFormAddress: null,
};
