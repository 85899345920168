export const fakeData = [
  {
    id: 1,
    propertyType: 'Casa',
    operation: 'sell',
    publicationDate: '2020-10-6',
    latitude: -33.429743,
    longitude: -70.568788,
    sellPrice: 200000,
    priceUnits: 'UF',
    sellValorization: 200200,
    pictures: [
      'https://images.unsplash.com/photo-1472224371017-08207f84aaae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
      'https://images.unsplash.com/photo-1449844908441-8829872d2607?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80',
      'https://www.propiedades-us.com/uploads/tipo-inversion/propiedades-tipo-inversion-4.jpg',
    ],
    bedRooms: 2,
    fullBathrooms: 4,
    coveredArea: 140,
    address: 'Pasaje Las Águilas 197 casa B, San Carlos de Apoquindo, Las Condes, RM. Chile',
  },
  {
    id: 2,
    propertyType: 'Depto',
    operation: 'sell',
    publicationDate: '2020-10-6',
    latitude: -33.418304,
    longitude: -70.553026,
    sellPrice: 17321,
    priceUnits: 'UF',
    sellValorization: 22530,
    pictures: [
      'https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F1026205392%2F0x0.jpg',
    ],
    address: 'Las Águilas 197, San Carlos, Las Condes, RM. Chile',
    bedRooms: 1,
    fullBathrooms: 3,
    coveredArea: 100,
  },
  {
    id: 3,
    propertyType: 'Casa',
    operation: 'sell',
    publicationDate: '2020-10-6',
    latitude: -33.398447,
    longitude: -70.593761,
    sellPrice: 19569,
    priceUnits: 'UF',
    sellValorization: 21000,
    pictures: [
      'https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F1026205392%2F0x0.jpg',
    ],
    address: 'Las Águilas 197, San Carlos, Las Condes, RM. Chile',
    bedRooms: 4,
    fullBathrooms: 7,
    coveredArea: 200,
  },
  {
    id: 4,
    propertyType: 'apartment',
    operation: 'sell',
    publicationDate: '2020-10-6',
    latitude: -33.487774,
    longitude: -70.738116,
    sellPrice: 10000,
    priceUnits: 'UF',
    sellValorization: 23000,
    pictures: [
      'https://plantationhomes.com.au/cms_uploads/images/15861_six-stages-of-building-a-home.jpg',
      'https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F1026205392%2F0x0.jpg',
    ],
    address: 'Las Águilas 197, San Carlos, Las Condes, RM. Chile',
    bedRooms: 1,
    fullBathrooms: 2,
    coveredArea: 130,
  },
  {
    id: 5,
    propertyType: 'apartment',
    operation: 'rent',
    publicationDate: '2020-10-6',
    latitude: -33.463426,
    longitude: -70.659412,
    rentPrice: 569,
    priceUnits: 'UF',
    rentValorization: 153,
    pictures: [
      'https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F1026205392%2F0x0.jpg',
    ],
    address: 'Pasaje Las Águilas 197 casa B, San Carlos de Apoquindo, Las Condes, RM. Chile',
    bedRooms: 3,
    fullBathrooms: 4,
    coveredArea: 180,
  },
];

export const fakeCollections = [
  {
    id: 1,
    name: 'Casas de playa',
    img:
      'https://www.kommetjiehideaways.co.za/wp-content/uploads/2020/05/blue-ocean-beach-house-37-250x250.jpg',
  },
  {
    id: 2,
    name: 'Deptos oriente',
    img: 'https://i.pinimg.com/474x/61/46/a6/6146a6eb38fc7be9dd76e5d0dc2650f7.jpg',
  },
  {
    id: 3,
    name: 'Casas de playa',
    img: 'https://i.pinimg.com/originals/2a/63/f2/2a63f2e0fb63f99160b4f76dba655260.jpg',
  },
  {
    id: 4,
    name: 'Deptos oriente',
    img: 'https://4.imimg.com/data4/KE/GP/MY-9583217/modern-wooden-beach-house-250x250.jpg',
  },
  {
    id: 5,
    name: 'Deptos oriente',
    img: 'https://i.pinimg.com/474x/18/d7/35/18d7354eb729859c24ada9d5a706dcfe.jpg',
  },
  {
    id: 6,
    name: 'Deptos oriente',
    img:
      'http://www.blakeneycottagecompany.co.uk/wp-content/uploads/2020/10/14207870-192c-49f3-867a-03afbfb431e5-250x250.jpg',
  },
  {
    id: 7,
    name: 'Casas de playa',
    img:
      'https://www.kommetjiehideaways.co.za/wp-content/uploads/2020/05/blue-ocean-beach-house-37-250x250.jpg',
  },
  {
    id: 8,
    name: 'Deptos oriente',
    img: 'https://i.pinimg.com/474x/61/46/a6/6146a6eb38fc7be9dd76e5d0dc2650f7.jpg',
  },
  {
    id: 9,
    name: 'Casas de playa',
    img: 'https://i.pinimg.com/originals/2a/63/f2/2a63f2e0fb63f99160b4f76dba655260.jpg',
  },
  {
    id: 10,
    name: 'Deptos oriente',
    img: 'https://4.imimg.com/data4/KE/GP/MY-9583217/modern-wooden-beach-house-250x250.jpg',
  },
  {
    id: 11,
    name: 'Deptos oriente',
    img: 'https://i.pinimg.com/474x/18/d7/35/18d7354eb729859c24ada9d5a706dcfe.jpg',
  },
  {
    id: 12,
    name: 'Deptos oriente',
    img:
      'http://www.blakeneycottagecompany.co.uk/wp-content/uploads/2020/10/14207870-192c-49f3-867a-03afbfb431e5-250x250.jpg',
  },
];

export default { fakeCollections, fakeData };
