//import { useReactiveVar } from "@apollo/client";
import { Select, Form, Input, Switch } from 'antd';
import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
//import { userTokenVar } from "../../graphql/cache";
import './EditPropertyForm.less';

const { Option } = Select;

const EditPropertyForm = (propertyData) => {
  //const userToken = useReactiveVar(userTokenVar);
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const inputSize = isMobile ? 'large' : 'large';
  //const buttonSize = isMobile ? "middle" : "large";

  return (
    <div className="edit-property-form">
      <Form
        form={form}
        //onFinish={onFinish}
        //onFinishFailed={onFinishFailed}
        layout={'vertical'}
        initialValues={{ propertydata: propertyData }}
      >
        <Form.Item
          name="propertyType"
          label={isMobile ? '' : 'Tipo de propiedad'}
          rules={[
            {
              required: true,
              message: 'Ingresa el tipo de propiedad',
            },
          ]}
          className="col-2"
        >
          <Select
            //onChange={(x) => setPropertyType(x)}
            value={propertyData.type}
            placeholder={'Casa o Departamento'}
            size={inputSize}
          >
            <Option value="house">Casa</Option>
            <Option value="apartment">Departamento</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="coveredArea"
          label={isMobile ? '' : 'Tamaño construido (área útil sin consederar patio/terraza)'}
          rules={[
            {
              required: true,
            },
          ]}
          className="col-2"
        >
          <Input
            value={propertyData.coveredArea}
            /*onChange={(x) => {
                    setCoveredAreaFormState(x.target.value);
                    }}*/
            type={'number'}
            placeholder="Área útil en m²"
            size={inputSize}
          />
        </Form.Item>

        <Form.Item
          name="totalArea"
          label={isMobile ? '' : 'Tamaño terreno (área total considerando patio/terraza)'}
          rules={[
            {
              required: true,
            },
          ]}
          className="col-2"
        >
          <Input
            value={propertyData.totalArea}
            /*onChange={(x) => {
                    setTotalAreaFormState(x.target.value);
                    }}*/
            type={'number'}
            placeholder="Área total en m²"
            size={inputSize}
          />
        </Form.Item>

        <Form.Item
          name="rooms"
          label={isMobile ? '' : 'Cantidad de habitaciones'}
          rules={[
            {
              required: true,
            },
          ]}
          className={isMobile ? 'col-2' : ''}
        >
          <Input
            value={propertyData.bedRooms}
            /*onChange={(x) => {
                    setRoomsFormState(x.target.value);
                    }}*/
            type={'number'}
            placeholder="Habitaciones"
            size={inputSize}
          />
        </Form.Item>
        <Form.Item
          name="bathrooms"
          label={isMobile ? '' : 'Cantidad de baños'}
          rules={[
            {
              required: true,
            },
          ]}
          className={isMobile ? 'col-2' : ''}
        >
          <Input
            value={propertyData.fullBathrooms}
            /*onChange={(x) => {
                    setBathroomsFormState(x.target.value);
                    }}*/
            type={'number'}
            placeholder="Baños"
            size={inputSize}
          />
        </Form.Item>
        <Form.Item
          name="parkings"
          label={isMobile ? '' : 'Capacidad estacionamiento'}
          rules={[
            {
              required: true,
            },
          ]}
          className={isMobile ? 'col-2' : ''}
        >
          <Input
            value={propertyData.parkings}
            /*onChange={(x) => {
                    setParkingsFormState(x.target.value);
                    }}*/
            type={'number'}
            placeholder="Estacionamiento"
            size={inputSize}
          />
        </Form.Item>
        {propertyData.type === 'house' ? (
          <Form.Item
            name="floors"
            label={isMobile ? '' : 'Cantidad de pisos'}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            className={isMobile ? 'col-2' : ''}
          >
            <Input
              value={propertyData.floors}
              /*onChange={(x) => {
                        setFloorsFormState(x.target.value);
                    }}*/
              type={'number'}
              placeholder="Pisos"
              size={inputSize}
            />
          </Form.Item>
        ) : (
          <Form.Item
            name="warehouses"
            label={isMobile ? '' : 'Bodegas'}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            className={isMobile ? 'col-2' : ''}
          >
            <Input
              value={propertyData.warehousesFormState}
              /*onChange={(x) => {
                        setwarehousesFormState(x.target.value);
                    }}*/
              type={'number'}
              placeholder="Bodegas"
              size={inputSize}
            />
          </Form.Item>
        )}
        {propertyData.type === 'house' ? (
          <Form.Item
            name="pool"
            label={isMobile ? '' : '¿Propiedad tiene piscina?'}
            valuePropName="checked"
            className="inline col-2"
          >
            <Switch
              checked={propertyData.swimmingPool}
              /*onChange={(x) => {
                        setPoolFormState(x);
                    }}*/
              size={inputSize}
            />
          </Form.Item>
        ) : (
          <Fragment>
            <Form.Item
              name="isDuplex"
              label="¿Es duplex?"
              valuePropName="checked"
              className="inline"
            >
              <Switch
                checked={propertyData.isDuplexFormState}
                /*onChange={(x) => {
                        setIsDuplexFormState(x);
                        }}*/
                size={inputSize}
              />
            </Form.Item>
            <Form.Item
              name="hasBalcony"
              label="¿Tiene bálcon?"
              valuePropName="checked"
              className="inline"
            >
              <Switch
                checked={propertyData.Balcony}
                /*onChange={(x) => {
                        setHasBalconyFormState(x);
                        }}*/
                size={inputSize}
              />
            </Form.Item>
          </Fragment>
        )}
      </Form>
    </div>
  );
};

export default EditPropertyForm;
