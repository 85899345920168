export const parsePropertiesAndMarkersData = (propertiesData) => {
  const parsedMarkersData = propertiesData.map((x) => ({
    id: x.id,
    latitude: x.point_location[1],
    longitude: x.point_location[0],
    operation_type: x.operation,
    property_type: x.real_estate_asset.property_type,
    price__value: x.last_published_price.amount,
  }));

  const parsedPropertiesData = propertiesData.map((x) => ({
    id: x.id,
    pictures: x.pictures?.map((picture) => picture.original_url) || [],
    address: x.adress_line,
    title: x.title,
    operation: x.operation,
    propertyType: x.real_estate_asset.property_type,
    latitude: x.point_location[1],
    longitude: x.point_location[0],
    sellPrice: x.last_published_price.amount,
    rentPrice: x.last_published_price.amount,
    sellValorization: x.last_published_price.amount,
    rentValorization: x.last_published_price.amount,
    publicationDate: new Date(x.created_time),
    coveredArea: x.real_estate_asset.covered_area,
    totalArea: x.real_estate_asset.total_area,
    bedRooms: x.real_estate_asset.rooms || 0,
    fullBathrooms: x.real_estate_asset.full_bathrooms || 0,
    floors: x.real_estate_asset.floors,
    parkings: x.real_estate_asset.parking_lots,
    swimmingPool: x.real_estate_asset.has_swimming_pool,
    selected: false,
    liked: false,
  }));
  return { parsedMarkersData, parsedPropertiesData };
};
