import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Devices, Logo } from '../../assets';
import './style.less';

export default function ValorizationLanding(props) {
  const { setAppraisalFormVisible } = props;

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const buttonSize = isDesktop ? 'large' : 'medium';

  const ValorizationButton = () => (
    <Button onClick={() => setAppraisalFormVisible(true)} size={buttonSize} type="primary">
      VALORIZACIÓN 1UF
    </Button>
  );

  return (
    <div className="new-landing-container2">
      <div className="new-landing-content">
        <div className="new-landing-main">
          <div className="new-landing-main-item">
            <div className="logo">
              <div>
                <Logo />
                <h1> TassApp</h1>{' '}
              </div>
              <Devices />
            </div>
            <div className="new-landing-map">
              <p2>
                En TassApp utilizamos un modelo de inteligencia artificial para calcular los
                intervalos de los detalles de las valorizaciones en tiempo real.
                <br></br>
                <br></br>
                Se obtiene con un intervalo de confianza del 90% de precisión, que se calcula en
                base a la variabilidad de las predicciones de nuestro modelo.
              </p2>
            </div>

            <div>
              <h2>Desbloquea tu valorización detallada hoy</h2>
            </div>
            <div>
              <p>
                Por sólo 1 UF podrás ver, descargar y compartir el detalle completo de la
                valorización de tu propiedad.
              </p>
            </div>
            <div>
              <p3>
                <li>Valorización instantánea para valor de venta y arriendo de la propiedad.</li>
                <br></br>
                <li>
                  Rango de intervalo negociable para precio de publicación y venta de la propiedad.
                </li>
                <br></br>
                <li>
                  Rango de intervalo negociable para precio de publicación y arriendo de la
                  propiedad.
                </li>
                <br></br>
                <li>Porcentaje de rentabilidad anual.</li>
                <br></br>
                <li>Rango de rentabilidad anual.</li>
                <br></br>
                <li>Propiedades comparables en el mercado. </li>
                <br></br>

                <div className="button">
                  <ValorizationButton />
                  <text>o</text>
                  <div className="linnk">
                    <p4>Subscríbete a los planes que tenemos para ti aquí.</p4>
                  </div>
                </div>
              </p3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ValorizationLanding.propTypes = {
  appraisalFormPropertyType: PropTypes.string,
  appraisalFormAddress: PropTypes.instanceOf(Object),
  setAppraisalFormPropertyType: PropTypes.func,
  setAppraisalFormAddress: PropTypes.func.isRequired,
  setAppraisalFormVisible: PropTypes.func.isRequired,
};

ValorizationLanding.defaultProps = {
  appraisalFormAddress: null,
};
