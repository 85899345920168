function grantAccess(role, permissions) {
  switch (role) {
    case "freeUser":
      switch (permissions) {
        case "profile":
          return true;
        default:
          return false;
      }
    case "advancedUser":
      switch (permissions) {
        case "properties":
          return true;
        case "massive-appraisals":
          return true;
        case "profile":
          return true;
        default:
          return false;
      }
    case "proUser":
      switch (permissions) {
        case "properties":
          return true;
        case "massive-appraisals":
          return true;
        case "profile":
          return true;
        default:
          return false;
      }
    case "adminUser":
      switch (permissions) {
        case "profile":
          return false;
        default:
          return true;
      }
    case "superAdmin":
      return true;
    default:
      return false;
  }
}

export default grantAccess;
