export const propertyTypeFilterValues = [
  { label: 'Casa', value: 'house' },
  { label: 'Departamento', value: 'apartment' },
];

export const operationTypeFilterValues = [
  { label: 'Venta', value: 'sell' },
  { label: 'Arriendo', value: 'rent' },
];

export const publishedDateFilterValues = [
  { label: 'Últimos 3 meses', value: 3 },
  { label: 'Últimos 6 meses', value: 5 },
  { label: 'Últimos 12 meses', value: 12 },
  { label: 'Todas', value: undefined },
];

export const sortFilterValues = [
  [
    {
      label: 'Menor tiempo publicado',
      value: 'published_time__latest',
    },
    {
      label: 'Mayor tiempo publicado',
      value: 'published_time__oldest',
    },
  ],
  [
    {
      label: 'Mayor precio publicado',
      value: 'published_price__higher',
    },
    {
      label: 'Menor precio publicado',
      value: 'published_price__lower',
    },
  ],
  [
    {
      label: 'Menor área útil',
      value: 'covered_area__smallest',
    },
    {
      label: 'Mayor área útil',
      value: 'covered_area__biggest',
    },
  ],
  [
    {
      label: 'Menor área total',
      value: 'total_area__smallest',
    },
    {
      label: 'Mayor área total',
      value: 'total_area__biggest',
    },
  ],
  [
    {
      label: 'Mayor rentabilidad',
      value: 'total_area__biggest',
    },
    {
      label: 'Menor rentabilidad',
      value: 'total_area__smallest',
    },
  ],
  [
    {
      label: 'Mayor rentabilidad',
      value: 'total_area__biggest',
    },
    {
      label: 'Menor rentabilidad',
      value: 'total_area__smallest',
    },
  ],
];

export const movilActionValues = [
  {
    label: 'Guardar en colección',
    value: 'save_property',
  },
  {
    label: 'Agregar a favoritos',
    value: 'fav_property',
  },
  {
    label: 'Descargar Exel',
    value: 'Download_porps',
  },
  {
    label: 'Deseleccionar',
    value: 'errase_selection',
  },
];

export const movilActionValuesAdmin = [
  {
    label: 'Guardar en colección',
    value: 'save_property',
  },
  {
    label: 'Agregar a favoritos',
    value: 'fav_property',
  },
  {
    label: 'Descargar Exel',
    value: 'Download_porps',
  },
  {
    label: 'Ocultar',
    value: 'hide_properties',
  },
  {
    label: 'Mostrar',
    value: 'show_properties',
  },
  {
    label: 'Deseleccionar',
    value: 'errase_selection',
  },
];

export const filtersInit = (query) => ({
  propertyTypeFilter: query.get('propertyTypeParam') ? query.get('propertyTypeParam') : 'house',
  operationTypeFilter: query.get('propertyOperationType')
    ? query.get('propertyOperationType')
    : 'sell',
  publishedPriceFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  roomsFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  bathroomsFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  coveredAreaFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  totalAreaFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  publishedDateFilter: undefined,
  sortFilter: 'published_time__latest',
});
