import { gql } from '@apollo/client';

const LOG_IN = gql`
  mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      role {
        id
        name
      }
      firstName
      lastName
      email
      id
    }
  }
`;

const LOG_OUT = gql`
  mutation {
    logOut
  }
`;

const SIGN_UP = gql`
  mutation signUp(
    $firstName: NonEmptyString!
    $lastName: NonEmptyString!
    $email: EmailAddress!
    $password: NonEmptyString!
    $passwordConfirmation: NonEmptyString!
    $phone: NonEmptyString
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

const GOOGLE_AUTH = gql`
  mutation googleAuth($googleToken: String!) {
    googleAuth(googleToken: $googleToken) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

const CREATE_VALORIZATION = gql`
  mutation createValorization($createValorizationInput: ValorizationInput!) {
    createValorization(input: $createValorizationInput) {
      id
    }
  }
`;

const CREATE_MASSIVE_VALORIZATION = gql`
  mutation createMassiveValorization($input: [ValorizationInput]!, $name: NonEmptyString) {
    createMassiveValorization(input: $input, name: $name) {
      id
    }
  }
`;
const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $roleId: Int) {
    updateUser(userId: $userId, roleId: $roleId) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;
const GET_REPORT_URL = gql`
  mutation getReportUrl($valorizationId: Int!, $shareToken: String) {
    getReportUrl(id: $valorizationId, shareToken: $shareToken)
  }
`;
const GET_KEY_FOR_SHARE_APPRAISAL = gql`
  mutation share($id: Int!) {
    shareValorization(id: $id)
  }
`;
const CREATE_USER = gql`
  mutation createUser(
    $firstName: NonEmptyString!
    $lastName: NonEmptyString!
    $password: NonEmptyString!
    $passwordConfirmation: NonEmptyString!
    $email: EmailAddress!
    $phone: NonEmptyString!
    $roleId: Int!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      password: $password
      passwordConfirmation: $passwordConfirmation
      email: $email
      phone: $phone
      roleId: $roleId
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

const DISABLE_USER = gql`
  mutation disableUser($userId: Int!) {
    disableUser(userId: $userId) {
      id
      firstName
      lastName
      email
    }
  }
`;

const ENABLE_USER = gql`
  mutation enableUser($userId: Int!) {
    enableUser(userId: $userId) {
      id
      firstName
      lastName
      email
    }
  }
`;
const HIDE_PUBLICATION = gql`
  mutation hidePublication($publicationId: ID!) {
    hide_unhide_publication(publicationId: $publicationId) {
      scalar
      Boolean
    }
  }
`;

export {
  LOG_IN,
  LOG_OUT,
  SIGN_UP,
  GOOGLE_AUTH,
  CREATE_VALORIZATION,
  CREATE_MASSIVE_VALORIZATION,
  GET_REPORT_URL,
  GET_KEY_FOR_SHARE_APPRAISAL,
  HIDE_PUBLICATION,
  UPDATE_USER,
  CREATE_USER,
  DISABLE_USER,
  ENABLE_USER,
};
