import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { Popover, Button } from 'antd';
import Icon from '@ant-design/icons';

import {
  IconArrowDown,
  IconHide,
  IconSaved,
  IconShow,
  IconLike,
  IconClose,
  IconDownload,
} from '../../assets';
import './style.less';

export default function SelectFilter(props) {
  const { label, defaultValue, options, callback } = props;
  const [value, setValue] = useState(defaultValue);
  const isWideScreen = useMediaQuery({ minWidth: 1366 });
  const antButtonSize = isWideScreen ? 'large' : 'middle';

  const handleChange = (value) => {
    setValue(value);
    if (callback) {
      //callback(value);
      console.log(value);
    }
  };

  return (
    <div className="filterPopover">
      <Popover
        content={<SelectFilterInput value={value} options={options} onChange={handleChange} />}
        placement="bottom"
      >
        <Button type="default" size={antButtonSize}>
          {label}
          <Icon component={IconArrowDown} />
        </Button>
      </Popover>
    </div>
  );
}

SelectFilter.propTypes = {
  ...SelectFilterInput.propTypes,
  label: PropTypes.string.isRequired,
};

export function SelectFilterInput({ value, options, onChange }) {
  const getItemClasses = (itemValue) => {
    const baseClasses = 'selectFilterItem';
    return value === itemValue ? baseClasses + ' active' : baseClasses;
  };

  return (
    <div className="selectFilterInput">
      {options.map(({ label, value }, idx) => (
        <span
          className={getItemClasses(value)}
          key={`${idx}-${value}`}
          value={value}
          onClick={() => onChange(value)}
        >
          {label}
          {value === 'save_property' && <Icon component={IconSaved} />}
          {value === 'fav_property' && <Icon component={IconLike} />}
          {value === 'Download_porps' && <Icon component={IconDownload} />}
          {value === 'hide_properties' && <Icon component={IconHide} />}
          {value === 'show_properties' && <Icon component={IconShow} />}
          {value === 'errase_selection' && <Icon component={IconClose} />}
        </span>
      ))}
    </div>
  );
}

SelectFilterInput.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  onClick: PropTypes.func,
};
