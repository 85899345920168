import React from "react";
import PropTypes from "prop-types";
import { GoogleLogin } from "react-google-login";
import { useMutation } from "@apollo/client";
import { message, Button } from "antd";
import Icon from "@ant-design/icons";

import { userRoleVar } from "../../graphql/cache";
import { GOOGLE_AUTH } from "../../graphql/mutations";
import { IconGoogle } from "../../assets";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const ButtonGoogleAuth = ({ onCompleted, onError }) => {
  const [googleAuth] = useMutation(GOOGLE_AUTH, {
    onCompleted,
    onError,
  });

  const handleGoogleSuccess = (response) => {
    if (response.accessToken) {
      googleAuth({
        variables: {
          googleToken: response.tokenId,
        },
      });
    }
  };

  return (
    <GoogleLogin
      clientId={CLIENT_ID}
      render={(renderProps) => (
        <Button
          size="large"
          onClick={renderProps.onClick}
          icon={<Icon component={IconGoogle} />}
        >
          Registrame con Google
        </Button>
      )}
      buttonText="Login"
      onSuccess={handleGoogleSuccess}
      cookiePolicy={"single_host_origin"}
    ></GoogleLogin>
  );
};

ButtonGoogleAuth.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
};

ButtonGoogleAuth.defaultProps = {
  onCompleted: (data) => userRoleVar(data.googleAuth.role.name),
  onError: (error) => {
    error.graphQLErrors.forEach((err) => message.error(err.message, 5));
  },
};

export default ButtonGoogleAuth;
