import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CardGrid from '../Properties/CardGrid';
import { fakeData } from '../../fakeData';
import Constants from '../../constants';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import PropertiesExcelDownload from '../Properties/ExcelDownload';

import { IconClose, IconTrash, IconDownload } from '../../assets';

const { IS_DESKTOP, IS_MOBILE } = Constants;

export const UserProfileSelectedCollection = () => {
  const [properties, setProperties] = useState(fakeData);
  const [selectedProperties, setSelectedProperties] = useState(new Set());

  const isDesktop = useMediaQuery(IS_DESKTOP);
  const isMobile = useMediaQuery(IS_MOBILE);

  const handleSelectProperty = (id, isSelected) => {
    const newSet = new Set(selectedProperties);
    if (isSelected) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedProperties(newSet);
  };

  const resetSelectedProperties = () => {
    setSelectedProperties(new Set());
  };

  const deleteSelectedProperty = () => {
    // Esto después se tiene que hacer en el onCompleted
    // de la mutación que elimina colecciones
    const newCollections = properties.filter((colection) => !selectedProperties.has(colection.id));
    //const deleted = _.differenceBy(properties, newCollections, 'id');
    setProperties(newCollections);
    //setSelectedProperties(new Set());
    //setDeletedTooltip({ show: true, deleted });
    //setTimeout(() => setDeletedTooltip({ ...deletedTooltip, show: false }), 7000);
  };

  return (
    <div className="collections-data-view">
      {isMobile && (
        <div className="collections-tag">
          <div className="text">Mis colecciones</div>
        </div>
      )}
      <div className="collections-view-header">
        <div className=" collections-view-header-count">
          {isDesktop
            ? `${properties.length} propiedades guardadas en colección`
            : `${properties.length} propiedades`}
        </div>
        <div className="collections-view-header-options">
          <div className="collections-view-header-text">
            {isDesktop
              ? `${selectedProperties.size} propiedades seleccionadas`
              : `${selectedProperties.size} seleccionadas`}
          </div>
          <div className="actions">
            <span className="collections-header-tools-first">
              <PropertiesExcelDownload
                render={
                  <Tooltip title="Descargar Excel">
                    <Button
                      icon={<Icon component={IconDownload} />}
                      type="link"
                      onClick={() => {}}
                    />
                  </Tooltip>
                }
                filename="TassApp Resumen de Propiedades"
                sheetName="Hoja 1"
                data={[]}
              ></PropertiesExcelDownload>
            </span>
            <span className="collections-header-tools">
              <Tooltip title="Eliminar propiedad">
                <Button
                  icon={<Icon component={IconTrash} />}
                  type="link"
                  onClick={() => deleteSelectedProperty()}
                />
              </Tooltip>
            </span>
            <span className="collections-header-tools">
              <Tooltip title="Deseleccionar">
                <Button
                  icon={<Icon component={IconClose} />}
                  type="link"
                  onClick={() => resetSelectedProperties()}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      <CardGrid
        loadingMore={true}
        gridLayout={'vertical'}
        dataCardState={properties}
        onCardClick={() => {}}
        seeMore={false}
        onSeeMoreClick={() => {}}
        setPropertyOnHook={() => {}}
        handleSelectProperty={handleSelectProperty}
        selectedProperties={selectedProperties}
        hidden={false}
      />
    </div>
  );
};

export default UserProfileSelectedCollection;
