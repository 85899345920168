import React, { useState } from 'react'; //useEffect,
import PropTypes from 'prop-types';
import { StaticMap } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers'; //, PolygonLayer
import { LightingEffect, AmbientLight, _SunLight as SunLight } from '@deck.gl/core';
import { scaleThreshold } from 'd3-scale';
import './style.less';
//import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

function getRndNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export const COLOR_SCALE = scaleThreshold()
  .domain([0, 0.3, 0.4, 0.5, 0.7, 0.9])
  .range([
    [248, 177, 149],
    [232, 153, 141],
    [216, 129, 133],
    [145, 102, 129],
    [99, 97, 127],
    [53, 92, 125],
  ]);

const INITIAL_VIEW_STATE = {
  latitude: -33.447487,
  longitude: -70.673676,
  zoom: 13,
  maxZoom: 16,
  maxPitch: 0,
  pitch: 0,
  bearing: 0,
  minZoom: 11,
};

const MAP_STYLE = 'mapbox://styles/gustav19x/ckgirm1xp24kd19pjn45kcalg';
const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
});

const dirLight = new SunLight({
  timestamp: Date.UTC(2019, 7, 1, 22),
  color: [255, 255, 255],
  intensity: 1.0,
  _shadow: false,
});

/*const landCover = [
  [
    [-123.0, 49.196],
    [-123.0, 49.324],
    [-123.306, 49.324],
    [-123.306, 49.196]
  ]
];*/

function getTooltip({ object }) {
  return (
    object && {
      html: `\
  <div><b>Average Property Value</b></div>
  <div>${object.properties.SHAPE_Area} / parcel</div>
  <div>${object.properties.SHAPE_Area} / m<sup>2</sup></div>
  <div><b>Growth</b></div>
  <div>${Math.round(object.properties.SHAPE_Area * 100)}%</div>
  `,
    }
  );
}

export default function IndicatorMap({ data }) {
  const [effects] = useState(() => {
    const lightingEffect = new LightingEffect({ ambientLight, dirLight });
    lightingEffect.shadowColor = [0, 0, 0, 0.5];
    return [lightingEffect];
  });

  const layers = [
    // only needed when using shadows - a plane for shadows to drop on
    new GeoJsonLayer({
      id: 'geojson',
      data,
      opacity: 0.1,
      stroked: true,
      filled: true,
      extruded: false,
      wireframe: true,
      getElevation: null,
      getFillColor: () => COLOR_SCALE(getRndNumber(0, 1)),
      getLineColor: [255, 255, 255],
      pickable: true,
      getLineWidth: 20,
    }),
  ];

  return (
    <DeckGL
      layers={layers}
      effects={effects}
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      getTooltip={getTooltip}
    >
      <StaticMap reuseMaps mapStyle={MAP_STYLE} preventStyleDiffing={true} />
    </DeckGL>
  );
}

IndicatorMap.propTypes = {
  data: PropTypes.object,
};
