export const houseSchema = {
  "Tamaño construido": {
    prop: "coveredArea",
    type: Number,
    required: true,
  },
  "Tamaño terreno": {
    prop: "totalArea",
    type: Number,
    required: true,
  },
  Habitaciones: {
    prop: "bedrooms",
    type: Number,
    required: true,
  },
  Baños: {
    prop: "bathrooms",
    type: Number,
    required: true,
  },
  Estacionamientos: {
    prop: "parkingLots",
    type: Number,
    required: true,
  },
  Pisos: {
    prop: "floors",
    type: Number,
    required: true,
  },
  Piscina: {
    prop: "hasSwimmingPool",
    required: true,
    type: (value) => {
      if (value === "SI") {
        return true;
      } else {
        return false;
      }
    },
  },
  Dirección: { prop: "address", required: true, type: String },
  Latitud: {
    prop: "latitude",
    required: true,
    type: (value) => {
      const floatValue = parseFloat(value);
      if (floatValue) {
        return floatValue;
      } else {
        throw new Error("wrong latitude");
      }
    },
  },
  Longitud: {
    prop: "longitude",
    required: true,
    type: (value) => {
      const floatValue = parseFloat(value);
      if (floatValue) {
        return floatValue;
      } else {
        throw new Error("wrong longitude");
      }
    },
  },
  Comuna: {
    prop: "city",
    required: true,
    type: String,
  },
  Región: {
    prop: "state",
    required: true,
    type: String,
  },
};

export const apartmentSchema = {
  "Tamaño construido": {
    prop: "coveredArea",
    type: Number,
    required: true,
  },
  "Tamaño terreno": {
    prop: "totalArea",
    type: Number,
    required: true,
  },
  Habitaciones: {
    prop: "bedrooms",
    type: Number,
    required: true,
  },
  Baños: {
    prop: "bathrooms",
    type: Number,
    required: true,
  },
  Estacionamientos: {
    prop: "parkingLots",
    type: Number,
    required: true,
  },
  Pisos: {
    prop: "floors",
    type: Number,
    required: true,
  },
  Balcón: {
    prop: "hasBalcony",
    required: true,
    type: (value) => {
      if (value === "SI") {
        return true;
      } else {
        return false;
      }
    },
  },
  Duplex: {
    prop: "isDuplex",
    required: true,
    type: (value) => {
      if (value === "SI") {
        return true;
      } else {
        return false;
      }
    },
  },
  Bodegas: {
    prop: "warehouses",
    required: true,
    type: Number,
  },
  Dirección: { prop: "address", required: true, type: String },
  Latitud: {
    prop: "latitude",
    required: true,
    type: (value) => {
      const floatValue = parseFloat(value);
      if (floatValue) {
        return floatValue;
      } else {
        throw new Error("wrong latitude");
      }
    },
  },
  Longitud: {
    prop: "longitude",
    required: true,
    type: (value) => {
      const floatValue = parseFloat(value);
      if (floatValue) {
        return floatValue;
      } else {
        throw new Error("wrong longitude");
      }
    },
  },
  Comuna: {
    prop: "city",
    required: true,
    type: String,
  },
  Región: {
    prop: "state",
    required: true,
    type: String,
  },
};
