import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import './style.less';
import moment from 'moment';
import 'moment/locale/es';
import Icon from '@ant-design/icons';
import { IconBathroom, IconBedroom, IconSquareMeters, IconTassApp } from '../../assets';
import ImageCarousel from './ImageCarousel';
import { Divider } from 'antd';

moment.locale('es');
const operationFormatter = { sell: 'venta', rent: 'arriendo' };
const propertyTypeFormatter = { house: 'Casa', apartment: 'Depto' };

export const PropertyCard = ({
  property,
  handleSelectedFavorite,
  isSelected,
  unlikeIndividualFavorite,
}) => {
  const {
    id,
    address,
    bedRooms,
    coveredArea,
    fullBathrooms,
    operation,
    propertyType,
    publicationDate,
    rentPrice,
    rentValorization,
    sellPrice,
    sellValorization,
  } = property;

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const isForSale = operation === 'sell';

  const propertyTypeText = `${propertyTypeFormatter[propertyType] || 'Casa'} en ${
    operationFormatter[operation] || 'venta'
  }`;

  const propertyDate = moment(publicationDate).fromNow();

  const price = `UF
    ${
      isForSale
        ? `${sellPrice ? Math.min(sellPrice, 9999999).toLocaleString('de-DE') : '-'}`
        : `${rentPrice ? Math.min(rentPrice, 999999).toLocaleString('de-DE') : '-'}/mes`
    }
        `;

  const estimatedPrice = `UF
    ${
      isForSale
        ? `${sellValorization ? Math.min(sellValorization, 9999999).toLocaleString('de-DE') : '-'}`
        : `${
            rentValorization ? Math.min(rentValorization, 999999).toLocaleString('de-DE') : '-'
          }/mes`
    }`;

  return (
    <div className="card-main-container" key={id}>
      <div className="property-card-image">
        <ImageCarousel
          property={property}
          handleSelectedFavorite={handleSelectedFavorite}
          isSelected={isSelected}
          unlikeIndividualFavorite={unlikeIndividualFavorite}
        />
      </div>
      <div className="card-description-container">
        <div className="card-column-flex-div-first">
          <div className="card-primary-text">{propertyTypeText}</div>
          <div className="card-secondary-text">{address}</div>
        </div>
        {!isMobile ? (
          <div className="card-column-flex-div">
            <div className="card-primary-text"> Publicado {propertyDate}</div>
            <div className="row-flex-div">
              <span className="card-secondary-text">
                <Icon component={IconBedroom} />
                &nbsp;{`${bedRooms} piezas`}
              </span>
              &nbsp;
              <span className="card-secondary-text">
                <Icon component={IconBathroom} />
                &nbsp;{`${fullBathrooms} baños`}
              </span>
            </div>
            <div className="row-flex-div">
              <span className="card-secondary-text">
                <Icon component={IconSquareMeters} />
                &nbsp;{`${coveredArea || '- '} m² útiles`}
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
        {isMobile ? '' : <Divider type="vertical" className="card-divider" />}
        <div className="card-column-flex-div">
          <div className="card-primary-text">Precio{isMobile ? ' ' : ' de '}venta:</div>
          <div className="card-secondary-text">Publicado: {isForSale ? price : '/'}</div>
          {isForSale ? (
            <div className={'card-secondary-text-purple'}>
              Estimado: {estimatedPrice} <Icon component={IconTassApp} />
            </div>
          ) : (
            <div className={'card-secondary-text'}>Estimado: {'/'}</div>
          )}
        </div>
        {isMobile ? '' : <Divider type="vertical" className="card-divider" />}
        <div className="card-column-flex-div">
          <div className="card-primary-text">Precio{isMobile ? ' ' : ' de '}arriendo:</div>
          <div className="card-secondary-text">Publicado: {!isForSale ? price : '/'}</div>
          {!isForSale ? (
            <div className={'card-secondary-text-purple'}>
              Estimado: {estimatedPrice} <Icon component={IconTassApp} />
            </div>
          ) : (
            <div className={'card-secondary-text'}>Estimado: {'/'}</div>
          )}
        </div>
      </div>
    </div>
  );
};

PropertyCard.defaultProps = {
  handleSelectedFavorite: () => {},
  isSelected: false,
  unlikeIndividualFavorite: () => {},
};

PropertyCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    bedRooms: PropTypes.number,
    coveredArea: PropTypes.number,
    fullBathrooms: PropTypes.number,
    operation: PropTypes.string,
    pictures: PropTypes.arrayOf(PropTypes.string),
    propertyType: PropTypes.string,
    publicationDate: PropTypes.string,
    rentPrice: PropTypes.number,
    rentValorization: PropTypes.number,
    sellPrice: PropTypes.number,
    sellValorization: PropTypes.number,
  }).isRequired,
  isSelected: PropTypes.bool,
  handleSelectedFavorite: PropTypes.func,
  unlikeIndividualFavorite: PropTypes.func,
};

export default PropertyCard;
