/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";

import { GET_CURRENT_USER_VALORIZATIONS } from "../../graphql/queries.js";
import AppraisalsList from "../Appraisal/List";
import Loading from "../Loading";

moment.locale("es");

export default function UserProfileAppraisals() {
  const [appraisalsIndex, setAppraisalsIndex] = useState(0);
  const [appraisals, setAppraisals] = useState([]);
  const [getMyValorizations, { loading }] = useLazyQuery(
    GET_CURRENT_USER_VALORIZATIONS,
    {
      variables: { limit: 11, offset: appraisalsIndex * 10 },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
        if (appraisalsIndex > 0) {
          setAppraisals([...appraisals, ...data.getMyValorizations.slice(1)]);
        } else {
          setAppraisals([...appraisals, ...data.getMyValorizations]);
        }
      },
      onError: (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }
      },
    }
  );

  useEffect(() => {
    getMyValorizations();
  }, [appraisalsIndex]);

  const hasMoreData = (appraisalsIndex + 1) * 10 < appraisals.length;
  const handleSeeMoreClick = () => setAppraisalsIndex(appraisalsIndex + 1);

  return loading && appraisals.length === 0 ? (
    <Loading />
  ) : (
    <div className="user-profile-appraisals-view">
      <AppraisalsList
        appraisals={appraisals}
        hasMoreData={hasMoreData}
        handleSeeMoreClick={handleSeeMoreClick}
      />
    </div>
  );
}
