import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import {
  userTokenVar,
  restoreUserToken,
  userRoleVar,
} from "../../graphql/cache";
import grantAccess from "../../roleAccess";

const PrivateRoute = ({ component: Component, permissions, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const userToken = useReactiveVar(userTokenVar);
  const userRole = useReactiveVar(userRoleVar);

  useEffect(() => {
    restoreUserToken();
  }, [userToken]);

  // Without this the user will fall into /404 on every F5 b
  // because userToken variable isn't getting instantly.
  useEffect(async () => {
    new Promise(() => setTimeout(() => setLoading(false), 50));
  }, []);

  return (
    <>
      {loading ? (
        <div>Cargando</div>
      ) : (
        <Route
          {...rest}
          render={(props) =>
            grantAccess(userRole, permissions) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/404" />
            )
          }
        />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
  permissions: PropTypes.string,
};

export default PrivateRoute;
