import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';

import { GET_REPORT_URL } from '../../graphql/mutations.js';
import { IconTrash, IconDownload, IconEyeOpened } from '../../assets';
import './list.less';

const propertyTypeFormatter = { house: 'Casa', apartment: 'Depto.' };

const AppraisalsList = ({ appraisals, hasMoreData, handleSeeMoreClick }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [loadingPDF, setLoadingPDF] = useState(new Set());

  const [createPDF] = useMutation(GET_REPORT_URL, {
    onCompleted: (data) => {
      window.open(data.getReportUrl);
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('graphQLErrors', graphQLErrors);
        console.log('networkError', networkError);
      }
      if (graphQLErrors) {
        // pass
      }
    },
  });

  const downloadPDF = async (id) => {
    setLoadingPDF(loadingPDF.add(id));
    await createPDF({
      variables: {
        valorizationId: id,
      },
    });
    setLoadingPDF(loadingPDF.delete(id));
  };

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const buttonSize = isMobile ? 'small' : 'middle';

  return (
    <Fragment>
      <table className="appraisals-table">
        <thead>
          <tr>
            <th className="location-header">Ubicación en mapa</th>
            <th className="address-header">Dirección de propiedad</th>
            <th className="type-header">Tipo de propiedad</th>
            <th className="created-at-header">Tiempo desde valorización</th>
            <th className="price-header">Valorización de venta</th>
            <th className="price-header">Valorización de arriendo</th>
            <th className="actions-header">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {appraisals.map((appraisal) => (
            <tr key={appraisal.id}>
              <td className="no-padding location-cell">
                <img
                  src={`https://api.mapbox.com/styles/v1/gustav19x/ckgirm1xp24kd19pjn45kcalg/static/pin-l+4D29DD(${appraisal.longitude},${appraisal.latitude})/${appraisal.longitude},${appraisal.latitude},14,0/200x125?access_token=${MAPBOX_TOKEN}`}
                />
              </td>

              <td className="text-align-left">{appraisal.address}</td>
              <td className="type-cell">{propertyTypeFormatter[appraisal.propertyType]}</td>
              <td className="created-at-cell">{moment(appraisal.createdAt).fromNow()}</td>
              <td className="price-cell">
                {Math.floor(appraisal.sellPrice).toLocaleString('de-DE')} UF
              </td>
              <td className="price-cell">
                {Math.floor(appraisal.rentPrice).toLocaleString('de-DE')} UF/mes
              </td>

              <td className="actions-cell">
                <Tooltip title="Ver detalle">
                  <Link to={`/appraisals/${appraisal.id}`}>
                    <Button
                      size={buttonSize}
                      type="link"
                      icon={<Icon component={IconEyeOpened} />}
                    />
                  </Link>
                </Tooltip>
                <Tooltip title="Descargar PDF">
                  <Button
                    size={buttonSize}
                    type="link"
                    loading={loadingPDF.has(appraisal.id)}
                    onClick={() => downloadPDF(appraisal.id)}
                    icon={<Icon component={IconDownload} />}
                  />
                </Tooltip>
                <Tooltip title="Eliminar valorización" placement="topRight">
                  <Button size={buttonSize} icon={<Icon component={IconTrash} />} type="link" />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {hasMoreData && (
        <Button className="btn-see-more" onClick={handleSeeMoreClick}>
          Ver más
        </Button>
      )}
    </Fragment>
  );
};

AppraisalsList.propTypes = {
  appraisals: PropTypes.array,
  hasMoreData: PropTypes.bool,
  handleSeeMoreClick: PropTypes.func,
};

export default AppraisalsList;
