import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Drawer, Tooltip } from 'antd';
import Icon from '@ant-design/icons';

import PropertiesExcelDownload from './ExcelDownload';
import Toggle from '../Buttons/ToggleButton';
import CloseFilter from '../Buttons/CloseFilterButton';
import RangeFilter, { RangeFilterInput } from '../Filters/Range';
import SelectFilter, { SelectFilterInput } from '../Filters/Select';
import ToggleFilter from '../Filters/Toggle';
import SliderFilter, { SliderFilterInput } from '../Filters/Slider';
import SortFilter from '../Filters/Sort';
import {
  propertyTypeFilterValues,
  operationTypeFilterValues,
  publishedDateFilterValues,
  sortFilterValues,
  filtersInit,
  movilActionValues,
  movilActionValuesAdmin,
} from './constants';
import {
  IconArrowDown,
  IconBookmark,
  IconClose,
  IconDownload,
  IconLikemini,
  IconHide,
  IconShow,
  IconSort,
} from '../../assets';

function rangeFilterFormater(obj) {
  const { minValue, maxValue } = obj;
  if (minValue && maxValue) {
    return `entre ${minValue} - ${maxValue}`;
  }
  if (minValue && !maxValue) {
    return `mayor a ${minValue}`;
  }
  if (!minValue && maxValue) {
    return `menor a ${maxValue}`;
  } else {
    return '';
  }
}

export default function PropertiesFilters({
  filters,
  setFilters,
  setGridLayout,
  dataCardState,
  selectedProperties,
  clearSelectedProperties,
  showCollectionsModal,
  hideSelectedProperties,
  showSelectedProperties,
  userRole,
}) {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const handleFilterChange = (key) => {
    return (newValue) => setFilters({ ...filters, [key]: newValue });
  };

  return (
    <>
      {isDesktop && (
        <PropertiesFiltersDesktop filters={filters} handleFilterChange={handleFilterChange} />
      )}
      <div className="results-header">
        <h2>Resultados de búsqueda</h2>
        <div className="results-header-options">
          <Toggle callback={setGridLayout} values={['horizontal', 'vertical']} />
          {isDesktop ? (
            <SortFilter
              label="Orden Publicaciones"
              defaultValue={filters.sortFilter}
              values={sortFilterValues}
              callback={handleFilterChange('sortFilter')}
            />
          ) : (
            <PropertiesFiltersMobile
              filters={filters}
              setFilters={setFilters}
              selectedProperties={selectedProperties}
              userRole={userRole}
            />
          )}
        </div>
      </div>
      <div className="map-header">
        <span>{selectedProperties.size} propiedades seleccionadas</span>
        <div className="actions">
          {userRole === 'admin' ||
            (userRole === 'superAdmin' && (
              <fragment>
                <Tooltip title="Ocultar Propiedad">
                  <Button
                    icon={<Icon component={IconHide} />}
                    type="link"
                    onClick={hideSelectedProperties}
                  />
                </Tooltip>
                <Tooltip title="Mostrar Propiedad">
                  <Button
                    icon={<Icon component={IconShow} />}
                    type="link"
                    onClick={showSelectedProperties}
                  />
                </Tooltip>
              </fragment>
            ))}
          <Tooltip title="Guardar propiedad" placement="top">
            <Button
              icon={<Icon component={IconBookmark} />}
              type="link"
              onClick={showCollectionsModal}
            />
          </Tooltip>
          <Tooltip title="Agregar a favoritos" placement="top">
            <Button icon={<Icon component={IconLikemini} />} type="link" onClick={() => {}} />
          </Tooltip>
          <PropertiesExcelDownload
            render={
              <Tooltip title="Descargar Excel">
                <Button
                  icon={<Icon component={IconDownload} />}
                  type="link"
                  onClick={clearSelectedProperties}
                />
              </Tooltip>
            }
            filename="TassApp Resumen de Propiedades"
            sheetName="Hoja 1"
            data={dataCardState && dataCardState.filter((x) => selectedProperties.has(x.id))}
          />
          <Tooltip title="Deseleccionar" placement="topRight">
            <Button
              icon={<Icon component={IconClose} />}
              type="link"
              onClick={clearSelectedProperties}
            />
          </Tooltip>
        </div>
      </div>
    </>
  );
}

PropertiesFilters.propTypes = {
  filters: PropTypes.shape({
    propertyTypeFilter: PropTypes.string.isRequired,
    operationTypeFilter: PropTypes.string.isRequired,
    publishedPriceFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    roomsFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    bathroomsFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    coveredAreaFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    totalAreaFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    publishedDateFilter: PropTypes.string,
    sortFilter: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  dataCardState: PropTypes.instanceOf(Array), // any is forbiden // falta def
  // resultsInsteadOfMap: PropTypes.bool.isRequired, // no se está usando
  // markersLoading: PropTypes.bool.isRequired, // no se está usandos
  setGridLayout: PropTypes.func.isRequired,
  selectedProperties: PropTypes.instanceOf(Object),
  clearSelectedProperties: PropTypes.func,
  showCollectionsModal: PropTypes.func,
  hideSelectedProperties: PropTypes.func,
  showSelectedProperties: PropTypes.func,
  userRole: PropTypes.string.isRequired,
};

PropertiesFilters.defaultProps = {
  dataCardState: null,
  selectedProperties: null,
  clearSelectedProperties: null,
  hideSelectedProperties: null,
  showSelectedProperties: null,
};

const PropertiesFiltersDesktop = ({ filters, handleFilterChange }) => {
  return (
    <>
      <div className="filtersOptions">
        <ToggleFilter
          defaultValue={filters.propertyTypeFilter}
          options={propertyTypeFilterValues}
          //label="Tipo de propiedad"
          callback={handleFilterChange('propertyTypeFilter')}
        />
        <ToggleFilter
          defaultValue={filters.operationTypeFilter}
          options={operationTypeFilterValues}
          //label={'Venta o Arriendo'}
          callback={handleFilterChange('operationTypeFilter')}
        />
        <RangeFilter
          title={'Precio publicado'}
          label={'Precio publicado'}
          prefix={'UF'}
          callback={handleFilterChange('publishedPriceFilter')}
        />
        <SliderFilter
          title={'Cantidad de dormitorios'}
          label={'Dormitorios'}
          callback={handleFilterChange('roomsFilter')}
        />
        <SliderFilter
          title={'Cantidad de baños'}
          label={'Baños'}
          callback={handleFilterChange('bathroomsFilter')}
        />
        <RangeFilter
          title={'Área útil (sin contar la terraza o patio)'}
          label={'Área útil'}
          suffix={'m²'}
          callback={handleFilterChange('coveredAreaFilter')}
        />
        <RangeFilter
          title={'Área total (área útil más terrazas y patios)'}
          label={'Área total'}
          suffix={'m²'}
          callback={handleFilterChange('totalAreaFilter')}
        />
        <SelectFilter
          label={'Tiempo publicación'}
          defaultValue={filters.publishedDateFilter}
          options={publishedDateFilterValues}
          callback={handleFilterChange('publishedDateFilter')}
        />
      </div>
      <div className="filterSelected">
        {filters.publishedPriceFilter.minValue || filters.publishedPriceFilter.maxValue ? (
          <CloseFilter
            label={`Precio en UF ${rangeFilterFormater(filters.publishedPriceFilter)}`}
            callback={handleFilterChange('publishedPriceFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.roomsFilter.minValue || filters.roomsFilter.maxValue ? (
          <CloseFilter
            label={`Dormitorios: ${rangeFilterFormater(filters.roomsFilter)}`}
            callback={handleFilterChange('roomsFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.bathroomsFilter.minValue || filters.bathroomsFilter.maxValue ? (
          <CloseFilter
            label={`Baños: ${rangeFilterFormater(filters.bathroomsFilter)}`}
            callback={handleFilterChange('bathroomsFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.coveredAreaFilter.minValue || filters.coveredAreaFilter.maxValue ? (
          <CloseFilter
            label={`Área útil: ${rangeFilterFormater(filters.coveredAreaFilter)}`}
            callback={handleFilterChange('coveredAreaFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.totalAreaFilter.minValue || filters.totalAreaFilter.maxValue ? (
          <CloseFilter
            label={`Área total: ${rangeFilterFormater(filters.totalAreaFilter)}`}
            callback={handleFilterChange('totalAreaFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.publishedDateFilter ? (
          <CloseFilter
            label={`Últimos ${filters.publishedDateFilter} meses`}
            callback={handleFilterChange('publishedDateFilter')}
            params={undefined}
          />
        ) : null}
      </div>
    </>
  );
};

PropertiesFiltersDesktop.propTypes = {
  filters: PropertiesFilters.propTypes.filters, // definir defaults
  handleFilterChange: PropTypes.func, // def default
};
PropertiesFiltersDesktop.defaultProps = {
  filters: null,
  handleFilterChange: null,
};

const PropertiesFiltersMobile = ({ filters, setFilters, selectedProperties, userRole }) => {
  const largeButton = useMediaQuery({ minWidth: 1200 });
  const antButtonSize = largeButton ? 'large' : 'middle';
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <Fragment>
      <Button size={antButtonSize} onClick={() => setDrawerVisible(true)}>
        Filtros <Icon component={IconArrowDown} />
      </Button>
      <Button size={antButtonSize}>
        <Icon component={IconSort} />
      </Button>
      <SelectFilter
        label={`${selectedProperties.size} seleccionados`}
        options={
          userRole === 'admin' || userRole === 'superAdmin'
            ? movilActionValuesAdmin
            : movilActionValues
        }
      />
      <Drawer
        title="Filtros"
        placement="right"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={360}
      >
        <PropertiesFiltersMobileOverlay
          filters={filters}
          setFilters={setFilters}
          setDrawerVisible={setDrawerVisible}
        />
      </Drawer>
    </Fragment>
  );
};

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const PropertiesFiltersMobileOverlay = ({ filters, setFilters, setDrawerVisible }) => {
  let query = useQueryParams();
  const [newFilters, setNewFilters] = useState(filters);
  const handleFilterChange = (key) => {
    return (newValue) => setNewFilters({ ...newFilters, [key]: newValue });
  };

  const applyFilters = () => {
    setFilters(newFilters);
    setDrawerVisible(false);
  };
  const resetFilters = () => {
    setNewFilters(filtersInit(query));
    setFilters(filtersInit(query));
  };

  const applyDisabled = JSON.stringify(filters) === JSON.stringify(newFilters);
  const resetDisabled = JSON.stringify(filtersInit(query)) === JSON.stringify(newFilters);

  const filtersComponents = [
    {
      label: 'Tipo de propiedad',
      component: SelectFilterInput,
      props: {
        value: newFilters.propertyTypeFilter,
        options: propertyTypeFilterValues,
        onChange: handleFilterChange('propertyTypeFilter'),
      },
    },
    {
      label: 'Venta o arriendo',
      component: SelectFilterInput,
      props: {
        value: newFilters.operationTypeFilter,
        options: operationTypeFilterValues,
        onChange: handleFilterChange('operationTypeFilter'),
      },
    },
    {
      label: 'Precio publicado',
      component: RangeFilterInput,
      props: {
        prefix: 'UF',
        minValue: newFilters.publishedPriceFilter.minValue,
        maxValue: newFilters.publishedPriceFilter.maxValue,
        onChange: handleFilterChange('publishedPriceFilter'),
      },
    },
    {
      label: 'Dormitorios',
      component: SliderFilterInput,
      props: {
        onChange: handleFilterChange('roomsFilter'),
      },
    },
    {
      label: 'Baños',
      component: SliderFilterInput,
      props: {
        onChange: handleFilterChange('bathroomsFilter'),
      },
    },
    {
      label: 'Área util',
      helper: 'sin contar terraza o patio',
      component: RangeFilterInput,
      props: {
        suffix: 'm²',
        minValue: newFilters.coveredAreaFilter.minValue,
        maxValue: newFilters.coveredAreaFilter.maxValue,
        onChange: handleFilterChange('coveredAreaFilter'),
      },
    },
    {
      label: 'Área total',
      helper: 'área útil más terraza o patio',
      component: RangeFilterInput,
      props: {
        suffix: 'm²',
        minValue: newFilters.totalAreaFilter.minValue,
        maxValue: newFilters.totalAreaFilter.maxValue,
        onChange: handleFilterChange('totalAreaFilter'),
      },
    },
    {
      label: 'Tiempo de publicación',
      component: SelectFilterInput,
      props: {
        value: newFilters.publishedDateFilter,
        options: publishedDateFilterValues,
        onChange: handleFilterChange('publishedDateFilter'),
      },
    },
  ];

  return (
    <div className="search-filters-drawer-content">
      {filtersComponents.map((filter, idx) => (
        <Fragment key={`${filter.label}-${idx.toString()}`}>
          <h4 className="search-filter-label">{filter.label}</h4>
          <filter.component
            //  onChange={filter.props.onChange()}
            value={filter.props.value}
            options={filter.props.options} /* ...filter.props esto está proibido */
          />
        </Fragment>
      ))}
      <div className="search-filters-drawer-buttons">
        <Button onClick={applyFilters} type="primary" disabled={applyDisabled}>
          Aplicar filtros
        </Button>
        <Button onClick={resetFilters} disabled={resetDisabled}>
          Eliminar filtros
        </Button>
        <Button onClick={() => setDrawerVisible(false)} type="link">
          Cancelar
        </Button>
      </div>
    </div>
  );
};

PropertiesFiltersMobile.propTypes = {
  filters: PropertiesFilters.propTypes.filters, //  propType "filters" is not required, but has no corresponding defaultProps declaration
  setFilters: PropTypes.func, // propType "setFilters" is not required, but has no corresponding defaultProps declaration
  setDrawerVisible: PropTypes.func.isRequired, //no se está usando y propType "setDrawerVisible" is not required, but has no corresponding defaultProps declaration
};
PropertiesFiltersMobile.defaultProps = {
  filters: null, //  propType "filters" is not required, but has no corresponding defaultProps declaration
  setFilters: null, // propType "setFilters" is not required, but has no corresponding defaultProps declaration
};
PropertiesFiltersMobileOverlay.propTypes = PropertiesFiltersMobile.propTypes;
