import React, { useState } from "react";
import PropTypes from "prop-types";
import { iconToggleList, iconToggleGrid } from "../../assets";

export default function Toggle({ callback, values }) {
  const [toggleState, setToggleState] = useState(values[1]);

  const handleClick = (value) => {
    if (callback) {
      callback(value);
    }
    setToggleState(value);
  };

  return (
    <div className="viewToggle">
      <img
        className={`toggleIcon${toggleState === values[0] ? "Active" : ""}`}
        onClick={() => handleClick(values[0])}
        src={iconToggleList}
        alt=""
      />
      <img
        className={`toggleIcon${toggleState === values[1] ? "Active" : ""}`}
        onClick={() => handleClick(values[1])}
        src={iconToggleGrid}
        alt=""
      />
    </div>
  );
}

Toggle.propTypes = {
  callback: PropTypes.func,
  values: PropTypes.array,
};
Toggle.defaultProps = {
  values: [false, true],
};
