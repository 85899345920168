import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { Button, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import Icon from "@ant-design/icons";

import { IconPin } from "../../assets";
import "./style.less";

const { Option } = Select;

function URLify(string) {
  return string.trim().replace(/\s/g, "%20");
}

export default function SearchDirectionBar({ setAddress, address }) {
  const [state, setState] = useState({
    data: [],
    address: {},
    fetching: false,
  });
  const [lastFetchId, setLastFetchId] = useState(0);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    if (address && address.value != state.address.value) {
      setState({ ...state, address: address });
    }
  }, [address]);
  const fetchUser = debounce((value) => {
    if (value == "") {
      return;
    }
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setState({ data: [], address: { value }, fetching: true });
    fetch(
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        URLify(value) +
        ".json?types=address&access_token=" +
        process.env.REACT_APP_MAPBOX_ACCESS_TOKEN +
        "&country=cl"
    )
      .then((response) => response.json())
      .then((body) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        const data = body.features.map((address) => ({
          value: address.place_name,
          coords: {
            latitude: address.center[1],
            longitude: address.center[0],
          },
        }));
        setState((prevState) => {
          return { ...prevState, data, fetching: false };
        });
      });
  }, 800);

  const handleChange = (value) => {
    var result = state.data.filter((address) => {
      return address.value == value;
    });
    if (setAddress) {
      setAddress(result[0]);
    }
    setState({
      data: [],
      address: result[0],
      fetching: false,
    });
  };

  const GetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const address = {
          value:
            "latitud: " +
            pos.coords.latitude +
            ", longitud: " +
            pos.coords.longitude,
          coords: {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          },
        };
        setState({
          data: [],
          address: address,
          fetching: false,
        });
        if (setAddress) {
          setAddress(address);
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="select-direction-bar-container">
      <Select
        showSearch
        size={isDesktop ? "large" : "medium"}
        showArrow={false}
        value={state.address.value}
        placeholder="Ingresa una dirección"
        notFoundContent={state.fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={fetchUser}
        onChange={handleChange}
        style={{ width: "100%" }}
      >
        {state.data.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
      <Button
        icon={<Icon component={IconPin} />}
        size={isDesktop ? "large" : "medium"}
        onClick={() => {
          GetUserLocation();
        }}
      />
    </div>
  );
}

SearchDirectionBar.propTypes = {
  setAddress: PropTypes.func,
  address: PropTypes.object,
};
