import React from "react";
import PropTypes from "prop-types";
import { Card, Skeleton } from "antd";

const bodyRows = {
  vertical: 4,
  horizontal: 2,
  simple: 0,
};

const className = {
  vertical: "cardContainer",
  horizontal: "cardContainer horizontal",
  simple: "cardContainer simple",
};
export default function SkeletonCard({ layout }) {
  return (
    <div className={className[layout]}>
      <Card
        className="skeletonCard"
        cover={<div className="cardCoverFit"></div>}
      >
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton
          active
          paragraph={{ rows: bodyRows[layout] }}
          className="skeletonBody"
        />
        <div className="cardFooter">
          <Skeleton.Button active className="cardFooterData" />
          <Skeleton.Button active className="cardFooterData" />
          <Skeleton.Button active className="cardFooterData dataArea" />
        </div>
      </Card>
    </div>
  );
}

SkeletonCard.propTypes = {
  layout: PropTypes.oneOf(["vertical", "horizontal", "simple"]),
};

SkeletonCard.defaultProps = {
  layout: "vertical",
};
