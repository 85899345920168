import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Switch, Route, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import UserProfileData from './Data';
import MassiveAppraisals from './MassiveAppraisals';
import UserProfileSideMenu from './SideMenu';
import UserProfileAppraisals from './Appraisals';
import Loading from '../Loading';
import { GET_ID_USER_DATA } from '../../graphql/queries';
import './style.less';

export default function UserAdminView(props) {
  const { userToken } = props;
  const [userName, setUserName] = useState();
  const [lastName, setLastName] = useState();
  const [userRole, setUserRole] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const { id } = useParams();
  const { loading } = useQuery(GET_ID_USER_DATA, {
    variables: {
      id: parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUserName(data.getUser.firstName);
      setLastName(data.getUser.lastName);
      setUserRole(data.getUser.role.name);
      setUserEmail(data.getUser.email);
      setUserPhone(data.getUser.phone);
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === 'development') {
        if (graphQLErrors) {
          console.log('getUser', graphQLErrors);
        }
        if (networkError) {
          console.log('getUser', networkError);
        }
      }
    },
  });

  const isDesktop = useMediaQuery({ minWidth: 1200 });
  return !loading && userName ? (
    <div className="user-profile-view">
      {isDesktop && (
        <UserProfileSideMenu
          title={`Perfil de ${userName || ''} ${lastName || ''}`.toUpperCase()}
        />
      )}
      <Switch>
        <Route path="/users/:id/massive-appraisals" render={() => <MassiveAppraisals />} />
        <Route
          path="/users/:id/appraisals"
          render={() => <UserProfileAppraisals userToken={userToken} />}
        />
        <Route
          path="/users/:id/profile"
          render={() => (
            <UserProfileData
              userName={userName}
              lastName={lastName}
              userRole={userRole}
              userEmail={userEmail}
              userPhone={userPhone}
            />
          )}
        />
      </Switch>
    </div>
  ) : (
    <Loading />
  );
}

UserAdminView.propTypes = {
  userToken: PropTypes.string.isRequired,
};
