import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.less';
import {
  TassAppLogoSimple,
  ArrowLeftShadow,
  ArrowRightShadow,
  likeSelected,
  likeUnselected,
  likeUnselectedLavander,
  likeSelectedLavander,
  IconBathroom,
  IconBedroom,
  IconSquareMeters,
  noPictureAvailable,
  IconLock,
} from '../../assets';
import moment from 'moment';
import { Tooltip } from 'antd';
import 'moment/locale/es';
import { Card as AntCard } from 'antd';
import Icon from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';

moment.locale('es');
const operationFormatter = { sell: 'venta', rent: 'arriendo' };
const propertyTypeFormatter = { house: 'Casa', apartment: 'Depto' };

const text =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Integer vel dictum dolor. Phasellus posuere massa et purus consectetur, non gravida lectus aliquam. Donec mauris mi, pellentesque quis sem non, ultricies accumsan velit. Nam auctor luctus libero, non imperdiet dolor lobortis vel. Pellentesque quis diam ac elit laoreet porttitor. Phasellus accumsan imperdiet dolor ac ultrices. Vestibulum libero lorem, molestie eget metus sit amet, tincidunt vulputate augue. Nullam quis urna nec dui mattis accumsan. ';

export default function Card({
  property,
  layout,
  onClick,
  onMouseEnter,
  onMouseLeave,
  handleSelectProperty,
  isSelected,
  isBlocked,
}) {
  const propertyTypeText = `${propertyTypeFormatter[property.propertyType] || 'Casa'} en ${
    operationFormatter[property.operation] || 'venta'
  }`;
  const propertyDate = moment(property.publicationDate).fromNow();
  const price = `UF
    ${
      property.operation == 'sell'
        ? `${
            property.sellPrice ? Math.min(property.sellPrice, 9999999).toLocaleString('de-DE') : '-'
          }`
        : `${
            property.rentPrice ? Math.min(property.rentPrice, 999999).toLocaleString('de-DE') : '-'
          }/mes`
    }
        `;

  const estimatedPrice = `UF
    ${
      property.operation == 'sell'
        ? `${
            property.sellValorization
              ? Math.min(property.sellValorization, 9999999).toLocaleString('de-DE')
              : '-'
          }`
        : `${
            property.rentValorization
              ? Math.min(property.rentValorization, 999999).toLocaleString('de-DE')
              : '-'
          }/mes`
    }`;

  const className = {
    vertical: 'cardContainer',
    horizontal: 'cardContainer horizontal',
    simple: 'cardContainer simple',
  };

  return (
    <div
      className={className[layout] + (property.parkings > 2 ? ' hiden' : '')}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      //id={property.parkings > 2 ? "hiden" : "showed"}
    >
      <AntCard
        cover={
          <CardCover
            property={property}
            handleSelectProperty={handleSelectProperty}
            isSelected={isSelected}
            isBlocked={isBlocked}
          />
        }
        hoverable
      >
        <div className="cardHeader">
          <div className="cardPublicationData">
            <span className="cardPropertyType">
              {isBlocked ? 'Casa en venta' : propertyTypeText}
            </span>
            <span className="cardPropertyDate">{isBlocked ? 'Hace 3 meses' : propertyDate}</span>
          </div>

          <div className="cardPriceSection">
            <span className="cardPrice">{isBlocked ? '' : price}</span>
            <div className="cardEstimatedPriceSection dummy">
              <span className="cardEstimatedPrice">{isBlocked ? '' : estimatedPrice}</span>
              <Tooltip placement="right" title={text}>
                <img src={TassAppLogoSimple} alt="TassApp" />
                <span className="cardEstimatedPriceLabel">Estimado*</span>
              </Tooltip>
            </div>
          </div>
        </div>

        {layout !== 'simple' && !isBlocked && <p className="cardDescription">{property.title}</p>}

        {isBlocked ? (
          <p className="cardDescription">
            <span className="blocked">
              <Icon component={IconLock} />
              Desbloquear detalles
            </span>
          </p>
        ) : (
          ''
        )}

        <div className="cardFooter">
          <span className="cardFooterData">
            {<Icon component={IconBedroom} />}
            {isBlocked ? '0' : property.bedRooms}
          </span>
          <span className="cardFooterData">
            {<Icon component={IconBathroom} />}
            {isBlocked ? '0' : property.fullBathrooms}
          </span>
          <span className="cardFooterData dataArea">
            {<Icon component={IconSquareMeters} />}
            {isBlocked ? '0' : `${property.coveredArea || '- '} m² útiles`}
          </span>
        </div>
      </AntCard>
    </div>
  );
}

Card.propTypes = {
  ...CardCover.propTypes,
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'simple']),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isSelected: PropTypes.bool,
  handleSelectProperty: PropTypes.func,
  isBlocked: PropTypes.bool,
};
Card.defaultProps = {
  layout: 'vertical',
  isSelected: false,
  isBlocked: false,
};

function CardCover({ property, handleSelectProperty, isSelected, isBlocked }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [liked, setLiked] = useState(property.liked);
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = property.pictures[photoIndex];
    if (property.pictures.length === 0) {
      setTheme(false);
    } else if (img.width >= img.height - 100) {
      setTheme(true);
    } else {
      setTheme(false);
    }
  }, [photoIndex]);

  const handleLike = () => {
    setLiked(!liked);
  };

  function changePhotoIndex(value) {
    const index = photoIndex + value;
    if (index < 0) {
      setPhotoIndex(property.pictures.length - 1);
    } else if (index >= property.pictures.length) {
      setPhotoIndex(0);
    } else {
      setPhotoIndex(index);
    }
  }

  return (
    <div>
      {isBlocked ? (
        <div
          className={theme ? 'cardCoverFit' : 'cardCover'}
          style={{
            backgroundImage: `url(${noPictureAvailable})`,
          }}
        ></div>
      ) : (
        <div
          className={theme ? 'cardCoverFit' : 'cardCover'}
          style={{
            backgroundImage: `url(${property.pictures[photoIndex] || noPictureAvailable})`,
          }}
        >
          <div className="cardCoverIcons">
            <Checkbox
              onChange={(e) => handleSelectProperty(property.id, e.target.checked)}
              onClick={(e) => e.stopPropagation()}
              checked={isSelected}
            />
            <div className="coverIcon">
              {liked ? (
                <img
                  src={theme ? likeSelected : likeSelectedLavander}
                  alt="like"
                  onClick={handleLike}
                />
              ) : (
                <img
                  src={theme ? likeUnselected : likeUnselectedLavander}
                  alt="like"
                  onClick={handleLike}
                />
              )}
            </div>
          </div>
          <div className="cardCoverImageSelector">
            {property.pictures.length > 1 && (
              <>
                <div
                  className="arrowSelector"
                  onClick={(e) => {
                    e.stopPropagation();
                    changePhotoIndex(-1);
                  }}
                >
                  <Icon component={ArrowLeftShadow} />
                </div>
                <div
                  className="arrowSelector"
                  onClick={(e) => {
                    e.stopPropagation();
                    changePhotoIndex(1);
                  }}
                >
                  <Icon component={ArrowRightShadow} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
CardCover.propTypes = {
  property: PropTypes.shape({
    propertyType: PropTypes.string,
    operation: PropTypes.string,
    sellPrice: PropTypes.number,
    publicationDate: PropTypes.instanceOf(Date),
  }).isRequired,
  handleSelectProperty: PropTypes.func,
  isSelected: PropTypes.bool,
  isBlocked: PropTypes.bool,
};

CardCover.defaultProps = {
  isBlocked: false,
};
