import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import DataCard from '../Cards/DataCard';
import Map from '../Map';
import { Button, Space, Tooltip } from 'antd';
import { Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import CardGrid from '../Properties/CardGrid';
import SortFilter from '../Filters/Sort';
import ToggleFilter from '../Filters/Toggle';
import PropertiesExcelDownload from '../Properties/ExcelDownload';
import { sortFilterValues, filtersInit, operationTypeFilterValues } from '../Properties/constants';
//import moment from 'moment';
import { parsePropertiesAndMarkersData } from '../Properties/services';
import {
  IconClose,
  IconDownload,
  IconTassApp,
  IconCoinHand,
  IconKeyHand,
  IconLink,
  IconSaved,
  IconLikemini,
  // IconLock,
} from '../../assets';
import { userTokenVar, restoreUserToken } from '../../graphql/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_VALORIZATION, GET_PROPERTIES_FROM_FILTER } from '../../graphql/queries';
import { GET_REPORT_URL, GET_KEY_FOR_SHARE_APPRAISAL } from '../../graphql/mutations';
import { useMutation, useLazyQuery } from '@apollo/client';
import './style.less';
import PaymentModal from '../PaymentModal';

const propertyTypeFormatter = { house: 'Casa', apartment: 'Departamento' };

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

function Appraisal({ valorizationId, shareToken }) {
  const query = useQueryParams();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [hideProperties, setHideProperties] = useState(true);
  const [selectedProperties, setSelectedProperties] = useState(new Set());
  const [filters, setFilters] = useState(filtersInit(query));
  const [propertyOnHook, setPropertyOnHook] = useState();
  const [singlePayment, setSinglePayment] = useState(false);

  const [propertyCardsIndex, setPropertyCardsIndex] = useState({
    index: 1,
    max: 6,
  });

  const cardScrollRef = useRef(null);
  const [dataCardState, setDataCardState] = useState();
  const [markersData, setMarkersData] = useState();
  const [paid, setPaid] = useState(false);
  const [polygonFilter, setPolygonFilter] = useState({
    type: 'Polygon',
    coordinates: [[]],
  });

  if (propertyOnHook) console.log('A');

  const { loading, data } = useQuery(GET_VALORIZATION, {
    variables: { id: valorizationId, shareToken: shareToken },

    onCompleted: (data) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Valorization Succeed:', data);
      }
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Valorization Failed:', error);
        if (error.graphQLErrors) {
          console.log(error.graphQLErrors);
        }
      }
    },
  });

  const [createdPDF, { loading: loadingPdf }] = useMutation(GET_REPORT_URL, {
    onCompleted: (data) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Valorization Succeed:', data);
      }
      window.open(data.getReportUrl);
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === 'development') {
        if (graphQLErrors) {
          console.log('graphQLErrors', graphQLErrors);
        }
        if (networkError) {
          console.log('networkError', networkError);
        }
      }
    },
  });

  const [createShareLink, { data: isLink, loading: loadingShareLink }] = useMutation(
    GET_KEY_FOR_SHARE_APPRAISAL,
    {
      onCompleted: (data) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('Valorization Succeed:', data);
        }
        navigator.clipboard.writeText(
          `${window.location.href}?shareToken=${data.shareValorization}`,
        );
      },
      onError: ({ graphQLErrors, networkError }) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('graphQLErrors', graphQLErrors);
          console.log('networkError', networkError);
        }
        if (graphQLErrors) {
          // pass
        }
        if (networkError) {
          for (let err of networkError.result.errors) {
            switch (err.extensions.code) {
              case 'BAD_USER_INPUT':
                userTokenVar('');
                window.location.href = '/';
            }
          }
        }
      },
    },
  );

  const downloadPDF = () => {
    createdPDF({
      variables: {
        valorizationId: valorizationId,
        shareToken: shareToken,
      },
    });
  };

  const copyLink = () => {
    if (shareToken) {
      navigator.clipboard.writeText(window.location.href);
    } else {
      createShareLink({
        variables: {
          id: valorizationId,
        },
      });
    }
  };

  // Queries
  const [getPropertiesFromFilter, { loading: markersLoading }] = useLazyQuery(
    GET_PROPERTIES_FROM_FILTER,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const { parsedPropertiesData, parsedMarkersData } = parsePropertiesAndMarkersData(
          data.getPropertiesFromFilter,
        );
        setDataCardState(parsedPropertiesData);
        setMarkersData(parsedMarkersData);
      },
      onError: (error) => {
        if (process.env.NODE_ENV === 'development') {
          console.log(error);
        }
      },
    },
  );

  // Effects
  useEffect(() => {
    setDataCardState(null);
    if (cardScrollRef.current) {
      cardScrollRef.current.scrollTo(0, 0);
    }

    setPropertyCardsIndex({
      index: 1,
      max: 12,
    });
    if (polygonFilter.coordinates[0].length > 0) {
      getPropertiesFromFilter({
        variables: {
          property_type: filters.propertyTypeFilter,
          operation_type: filters.operationTypeFilter,
          published_price__min: parseFloat(filters.publishedPriceFilter.minValue),
          published_price__max: parseFloat(filters.publishedPriceFilter.maxValue),
          rooms__min: parseInt(filters.roomsFilter.minValue, 10),
          rooms__max: parseInt(filters.roomsFilter.maxValue, 10),
          bathrooms__min: parseInt(filters.bathroomsFilter.minValue, 10),
          bathrooms__max: parseInt(filters.bathroomsFilter.maxValue, 10),
          covered_area__min: parseFloat(filters.coveredAreaFilter.minValue),
          covered_area__max: parseFloat(filters.coveredAreaFilter.maxValue),
          total_area__min: parseFloat(filters.totalAreaFilter.minValue),
          total_area__max: parseFloat(filters.totalAreaFilter.maxValue),
          polygon: polygonFilter,
        },
      });
    }
  }, [
    polygonFilter,
    filters.propertyTypeFilter,
    filters.operationTypeFilter,
    filters.publishedPriceFilter,
    filters.roomsFilter,
    filters.bathroomsFilter,
    filters.coveredAreaFilter,
    filters.totalAreaFilter,
    filters.sortFilter,
  ]);

  const handleSelectProperty = (id, isSelected) => {
    const newSet = new Set(selectedProperties);
    if (isSelected) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedProperties(newSet);
  };

  const resetSelectedProperties = () => {
    setSelectedProperties(new Set());
  };

  const handleFilterChange = (key) => {
    return (newValue) => setFilters({ ...filters, [key]: newValue });
  };

  const buttonSize = isMobile ? 'middle' : 'large';
  const cardSize = isMobile ? 'small' : 'middle';

  const blockedMessage = (
    <div
      onClick={() => {
        setSinglePayment(true);
        setPaid(true);
      }}
      className="blocked"
    >
      {/*  <div className="icon">
       <Icon component={IconLock} style={isMobile ? { fontSize: '12px' } : { fontSize: '16px' }} />
      </div> */}{' '}
      Desbloquear detalles
    </div>
  );

  return loading ? (
    <></>
  ) : data && data.getValorization ? (
    <div className="appraisal-view">
      <PaymentModal visible={singlePayment} setVisible={setSinglePayment} />
      <div className="appraisal-summary">
        <h1 className="appraisal-header">Detalle de valorización</h1>

        <div className="appraisal-summary-content">
          <div className="appraisal-summary-property">
            <p className="appraisal-summary-address">
              {data.getValorization.address || 'Dirección exacta no disponible :('}
            </p>
            <div className="map-container">
              <Map
                config={{
                  searchbar: false,
                  polygon: false,
                  markerUbication: true,
                  ubication: false,
                  zoomButtons: true,
                  satelitalControl: false,
                  propertiesAlerts: false,
                  calculateArea: false,
                }}
                withSearchBar={false}
                address={{
                  value: data.getValorization.address,
                  coords: {
                    latitude: data.getValorization.latitude,
                    longitude: data.getValorization.longitude,
                  },
                }}
              ></Map>
            </div>

            <div className={`summary-grid${isMobile && hideProperties ? ' summarized' : ''}`}>
              <span>
                <strong>Tipo de propiedad: </strong>
                {propertyTypeFormatter[data.getValorization.propertyType]}
              </span>

              <span>
                <strong>Cantidad de habitaciones: </strong>
                {data.getValorization.bedrooms}
              </span>

              <span>
                <strong>Tamaño construido (área útil): </strong>
                {`${data.getValorization.coveredArea} m²`}
              </span>

              <span>
                <strong> Cantidad de baños: </strong>
                {data.getValorization.bathrooms}
              </span>

              <span className="summarized-hide">
                <strong> Tamaño terreno (área total): </strong>
                {`${data.getValorization.totalArea} m²`}
              </span>

              {data.getValorization.propertyType === 'house' ? (
                <>
                  <span className="summarized-hide">
                    <strong> Cantidad de pisos: </strong>
                    {data.getValorization.floors}
                  </span>
                </>
              ) : (
                <>
                  <span className="summarized-hide">
                    <strong> Cantidad de bodegas: </strong>
                    {data.getValorization.warehouses}
                  </span>
                </>
              )}

              <span className="summarized-hide">
                <strong>Capacidad estacionamientos: </strong>
                {`${data.getValorization.parkingLots} autos`}
              </span>

              {data.getValorization.propertyType === 'house' ? (
                <>
                  <span className="summarized-hide">
                    <strong>¿Propiedad tiene piscina? </strong>
                    {data.getValorization.hasSwimmingPool ? 'Si' : 'No'}
                  </span>
                </>
              ) : (
                <>
                  <span className="summarized-hide">
                    <strong>¿Propiedad con balcón? </strong>
                    {data.getValorization.hasBalcony ? 'Si' : 'No'}
                  </span>
                </>
              )}
            </div>
            <Button
              size="small"
              onClick={() => setHideProperties(!hideProperties)}
              className="btn-see-more"
            >
              {hideProperties ? 'Ver más' : 'Ver menos'}
            </Button>
          </div>

          <div className="appraisal-summary-result">
            <h2>Valores estimados por TassApp</h2>

            <DataCard
              size={cardSize}
              icon={IconTassApp}
              title={'Valor estimado de venta'}
              body={
                paid
                  ? `UF ${data.getValorization.sellPrice.toLocaleString('de-DE')}`
                  : blockedMessage
              }
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconTassApp}
              title={'Rango estimado de venta'}
              body={
                paid
                  ? `UF ${parseInt(data.getValorization.lowerBoundSellCI, 10).toLocaleString(
                      'de-DE',
                    )}-${parseInt(data.getValorization.upperBoundSellCI, 10).toLocaleString(
                      'de-DE',
                    )}`
                  : blockedMessage
              }
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconKeyHand}
              title={'Valor estimado de arriendo'}
              body={
                paid
                  ? `UF ${data.getValorization.rentPrice.toLocaleString('de-DE')}/mes`
                  : blockedMessage
              }
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconKeyHand}
              title={'Rango estimado de arriendo'}
              body={
                paid
                  ? `UF ${parseInt(data.getValorization.lowerBoundRentCI, 10).toLocaleString(
                      'de-DE',
                    )}-${parseInt(data.getValorization.upperBoundRentCI, 10).toLocaleString(
                      'de-DE',
                    )}/mes`
                  : blockedMessage
              }
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconCoinHand}
              title={'Rentabilidad anual'}
              body={
                paid
                  ? `${(data.getValorization.yield * 100).toLocaleString('de-DE')}%`
                  : blockedMessage
              }
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconCoinHand}
              title={'Rango de rentabilidad'}
              body={
                paid
                  ? `${(data.getValorization.lowerBoundYieldCI * 100).toLocaleString('de-DE')}%-${(
                      data.getValorization.upperBoundYieldCI * 100
                    ).toLocaleString('de-DE')}%`
                  : blockedMessage
              }
            ></DataCard>
            <Button
              loading={loadingPdf}
              size={buttonSize}
              type="primary"
              onClick={
                paid
                  ? downloadPDF
                  : () => {
                      setPaid(true);
                    }
              }
              icon={<Icon component={IconDownload} />}
            >
              Descargar PDF
            </Button>
            <Button
              size={buttonSize}
              type="primary"
              loading={loadingShareLink}
              onClick={
                paid
                  ? copyLink
                  : () => {
                      setPaid(true);
                    }
              }
              icon={<Icon component={IconLink} />}
            >
              {isLink && !loadingShareLink && (
                <div onClick={(e) => e.stopPropagation()} className={'copy-popup'}>
                  Enlace copiado!
                </div>
              )}
              Copiar Enlace
            </Button>
          </div>
        </div>
      </div>

      <Space direction="vertical">
        <br></br>
        <h1 className="appraisal-header">Propiedades similares</h1>
        <br></br>
      </Space>
      {isDesktop ? (
        <div className="similar-properties-description">
          Nuestros algoritmos de inteligencia artificial buscan propiedades similares a tu propiedad
          sobre toda la oferta de mercado disponible para que puedas evaluar cuales propiedades en
          el mercado podrían competir con tu propiedad.
        </div>
      ) : (
        ''
      )}
      <Row>
        <Col
          xs={12}
          sm={{ span: 6, order: 1 }}
          md={{ span: 6, order: 1 }}
          lg={{ span: 8, order: 1 }}
        >
          <div className="similar-properties-header">
            {isDesktop ? (
              <ToggleFilter
                defaultValue={filters.operationTypeFilter}
                options={operationTypeFilterValues}
                //label={'Venta o Arriendo'}
                callback={handleFilterChange('operationTypeFilter')}
              />
            ) : (
              ''
            )}
            <div className="properties-counter">
              {markersData ? markersData.length : 0}{' '}
              {!isDesktop ? 'resultados' : 'resultados encontrados'}
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          sm={{ span: 8, order: 3 }}
          md={{ span: 8, order: 3 }}
          lg={{ span: 5, order: 2 }}
        >
          <SortFilter
            className="sort-filter"
            label={'Ordenar resultados'}
            defaultValue={filters.sortFilter}
            values={sortFilterValues}
            callback={handleFilterChange('sortFilter')}
          ></SortFilter>
        </Col>
        <Col
          xs={24}
          sm={{ span: 10, order: 2 }}
          md={{ span: 10, order: 2 }}
          lg={{ span: 11, order: 3 }}
        >
          <div className="map-header">
            <span>
              {selectedProperties.size} {isDesktop ? 'Propiedades seleccionadas' : 'seleccionados'}
            </span>
            <div className="actions">
              <Tooltip title="Agregar a colección">
                <Button icon={<Icon component={IconSaved} />} type="link" onClick={() => {}} />
              </Tooltip>
              <Tooltip title="Agregar a favoitos">
                <Button icon={<Icon component={IconLikemini} />} type="link" onClick={() => {}} />
              </Tooltip>
              <PropertiesExcelDownload
                render={
                  <Tooltip title="Descargar Excel">
                    <Button
                      icon={<Icon component={IconDownload} />}
                      type="link"
                      onClick={() => {}}
                    />
                  </Tooltip>
                }
                filename="TassApp Resumen de Propiedades"
                sheetName="Hoja 1"
                data={[]}
              ></PropertiesExcelDownload>
              <Tooltip title="Deseleccionar" placement="topRight">
                <Button
                  icon={<Icon component={IconClose} />}
                  type="link"
                  onClick={() => resetSelectedProperties()}
                />
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={13} xl={13} style={{ 'margin-bottom': '20px' }}>
          <div className="similar-properties-display">
            <CardGrid
              loadingMore={markersLoading}
              gridLayout={'vertical'}
              dataCardState={dataCardState}
              isBlocked={!paid}
              onCardClick={() => setPaid(true)}
              seeMore={markersData?.length > propertyCardsIndex.max * propertyCardsIndex.index}
              onSeeMoreClick={() =>
                setPropertyCardsIndex({
                  max: propertyCardsIndex.max,
                  index: propertyCardsIndex.index + 1,
                })
              }
              setPropertyOnHook={setPropertyOnHook}
              handleSelectProperty={handleSelectProperty}
              selectedProperties={selectedProperties}
              hidden={false}
            />
          </div>
        </Col>

        <Col xs={0} sm={24} md={24} lg={11} xl={11} style={{ 'margin-bottom': '20px' }}>
          <div className="map-container-2">
            <Map
              forcePopup={propertyOnHook}
              handleSelectProperty={handleSelectProperty}
              selectedProperties={selectedProperties}
              config={{
                searchbar: false,
                polygon: false,
                markerUbication: true,
                ubication: true,
                zoomButtons: true,
                satelitalControl: true,
                propertiesAlerts: true,
                calculateArea: false,
              }}
              withSearchBar={false}
              address={{
                value: data.getValorization.address,
                coords: {
                  latitude: data.getValorization.latitude,
                  longitude: data.getValorization.longitude,
                },
              }}
              markersData={markersData}
              isLoadingMarkers={markersLoading}
              dataCard={dataCardState}
              callback={(polygon) => {
                for (var i = 0; i < polygon.coordinates[0].length; i++) {
                  if (polygonFilter.coordinates[0][i]) {
                    if (
                      polygon.coordinates[0][i][0] != polygonFilter.coordinates[0][i][0] ||
                      polygon.coordinates[0][i][1] != polygonFilter.coordinates[0][i][1]
                    ) {
                      setPolygonFilter(polygon);
                      break;
                    }
                  } else {
                    setPolygonFilter(polygon);
                    break;
                  }
                }
              }}
              isBlocked={!paid}
            ></Map>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  );
}

Appraisal.propTypes = {
  valorizationId: PropTypes.number.isRequired,
  shareToken: PropTypes.string,
};

export default function AppraisalView(props) {
  AppraisalView.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any),
  };
  const userToken = useReactiveVar(userTokenVar);

  useEffect(() => {
    restoreUserToken();
  }, []);

  const { match } = props;
  let params = new URLSearchParams(props.location.search);
  return userToken || params.get('shareToken') ? (
    <Appraisal
      shareToken={params.get('shareToken')}
      valorizationId={parseInt(match.params.id, 10) || 0}
    ></Appraisal>
  ) : (
    <></>
  );
}
