import { InMemoryCache } from "@apollo/client";
import makePersistentVar from "./makePersistentVar";

const userTokenCacheConfig = {
  storage: window.localStorage,
  storageKey: "@userToken",
};
export const [userTokenVar, restoreUserToken] = makePersistentVar(
  "",
  userTokenCacheConfig
);

const userRoleCacheConfig = {
  storage: window.localStorage,
  storageKey: "@userRole",
};
export const [userRoleVar, restoreUserRole] = makePersistentVar(
  "",
  userRoleCacheConfig
);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userToken: {
          read() {
            return userTokenVar();
          },
        },
        userRole: {
          read() {
            return userRoleVar();
          },
        },
      },
    },
  },
});
