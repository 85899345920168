import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Button, Drawer, Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';
import _ from 'lodash';

import { userTokenVar, restoreUserToken, userRoleVar, restoreUserRole } from '../../graphql/cache';
import { LOG_OUT } from '../../graphql/mutations';
import UserProfileSideMenu from '../UserProfile/SideMenu';
import { IconUser } from '../../assets';
import { TassAppFullLogo } from '../../assets';
import './style.less';

const desktopNavLabels = {
  search: 'Buscar Propiedades',
  valorization: 'Valorizar Propiedades',
  massiveValorization: 'Valorización Masiva',
  indicators: 'Indicadores del Mercado',
};

const tabletNavLabels = {
  search: 'Buscar',
  valorization: 'Valorizar',
  massiveValorization: 'Valorización Masiva',
  indicators: 'Indicadores',
};

const mobileNavLabels = {
  search: 'Buscar',
  valorization: 'Valorizar',
  indicators: 'Indicadores',
};

const authSessionLabels = {
  profile: 'Mi perfil',
  logOut: 'Cerrar sesión',
};

const visitSessionLabels = {
  logIn: 'Iniciar sesión',
  signUp: 'Crear cuenta',
};

const { Header } = Layout;

function Navbar({ setModalAuthType, setAppraisalFormVisible }) {
  const history = useHistory();
  const [navLabels, setNavLabels] = useState(desktopNavLabels);

  const wideNavbar = useMediaQuery({ minWidth: 1200 });
  const mediumNavbar = useMediaQuery({
    minDeviceWidth: 768,
    maxWidth: 1365,
  });

  useEffect(() => {
    restoreUserToken();
    restoreUserRole();
  }, []);

  useEffect(() => {
    if (wideNavbar) {
      setNavLabels(desktopNavLabels);
    } else if (mediumNavbar) {
      setNavLabels(tabletNavLabels);
    } else {
      setNavLabels(mobileNavLabels);
    }
  }, [wideNavbar, mediumNavbar]);

  const navKeyToAction = {
    search: () => history.push('/properties'),
    // valorization: () => setAppraisalFormVisible(true),
    valorization: () => history.push('/appraisal'),
    massiveValorization: () => history.push('/massive-appraisals'),
    indicators: () => history.push('/metrics'),
  };

  const handleNavClick = ({ key }) => {
    setModalAuthType();
    setAppraisalFormVisible(false);
    navKeyToAction[key]();
  };

  return (
    <Header>
      <Link to="/" className="navLogo">
        <img src={TassAppFullLogo} alt="Tassapp" />
      </Link>

      <Menu onClick={handleNavClick} mode="horizontal" className="nav-labels">
        {Object.entries(navLabels).map(([key, label]) => (
          <Menu.Item key={key}>{label}</Menu.Item>
        ))}
      </Menu>

      {wideNavbar ? (
        <DesktopSessionControls setModalAuthType={setModalAuthType} />
      ) : (
        <MobileSessionDrawer
          setModalAuthType={setModalAuthType}
          setAppraisalFormVisible={setAppraisalFormVisible}
        />
      )}
    </Header>
  );
}

export default Navbar;

Navbar.propTypes = {
  setModalAuthType: PropTypes.func,
  setAppraisalFormVisible: PropTypes.func,
};

const DesktopSessionControls = ({ setModalAuthType }) => {
  const history = useHistory();
  const [sessionLabels, setSessionLabels] = useState(visitSessionLabels);
  const userToken = useReactiveVar(userTokenVar);
  const userRole = useReactiveVar(userRoleVar);

  const [logOut] = useMutation(LOG_OUT, {
    onError: ({ networkError }) => {
      if (networkError) {
        for (let err of networkError.result.errors) {
          switch (err.extensions.code) {
            case 'BAD_USER_INPUT':
              userTokenVar('');
              history.push('/');
          }
        }
      }
    },

    onCompleted: () => {
      userTokenVar('');
      userRoleVar('');
      window.localStorage.setItem('@refreshToken', '');
      history.push('/');
    },
  });

  useEffect(() => {
    if (userToken) {
      const newSessionLabels = _.clone(authSessionLabels);
      if (userRole === 'admin') {
        delete newSessionLabels.profile;
      }
      setSessionLabels(newSessionLabels);
    } else {
      setSessionLabels(visitSessionLabels);
    }
  }, [userToken, userRole]);

  const sessionKeyToAction = {
    profile: () => history.push('/user/profile'),
    logOut: logOut,
    logIn: () => setModalAuthType('logIn'),
    signUp: () => setModalAuthType('signUp'),
  };

  const handleSessionClick = ({ key }) => sessionKeyToAction[key]();
  return (
    <Menu onClick={handleSessionClick} mode="horizontal" className="sessionLabels">
      {Object.entries(sessionLabels).map(([key, label]) => (
        <Menu.Item key={key}>{label}</Menu.Item>
      ))}
    </Menu>
  );
};

DesktopSessionControls.propTypes = {
  setModalAuthType: PropTypes.func,
};

function MobileSessionDrawer({ setModalAuthType, setAppraisalFormVisible }) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const userToken = useReactiveVar(userTokenVar);

  const handleProfileButtonClick = () =>
    userToken ? setDrawerVisible(true) : setModalAuthType('logIn');

  return (
    <Fragment>
      <Drawer
        placement="right"
        className="right-drawer"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
      >
        <UserProfileSideMenu
          onClick={() => {
            setDrawerVisible(false);
            setModalAuthType();
            setAppraisalFormVisible(false);
          }}
        />
      </Drawer>
      <Button
        onClick={handleProfileButtonClick}
        type="link"
        shape="circle"
        className={`nav-profile-button${userToken ? ' authenticated' : ''}`}
        icon={<Icon component={IconUser} />}
      />
    </Fragment>
  );
}

MobileSessionDrawer.propTypes = {
  ...DesktopSessionControls.propTypes,
  setAppraisalFormVisible: PropTypes.func,
};
