import React from "react";
import PropTypes from "prop-types";
import Icon from "@ant-design/icons";

import "./style.less";

export default function DataCard(props) {
  const { icon, title, body, footer, outlined, size } = props;

  return (
    <div
      className={`data-card-container ${size}${outlined ? " outlined" : ""}`}
    >
      <Icon component={icon} />
      <h3>{title}</h3>
      <span className="data-card-body">{body}</span>
      <span className="data-card-footer">{footer}</span>
    </div>
  );
}

DataCard.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,
  outlined: PropTypes.bool,
  size: PropTypes.string,
};
DataCard.defaultProps = {
  footer: "",
  outlined: false,
  size: "medium",
};
