import React, { useState } from 'react';
import { Layout, Menu, Tabs } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_METRIC_TYPES } from '../../graphql/queries';
import DATA from './all.json';
import SearchDirectionBar from '../SearchDirectionBar';
import SelectFilter from '../Filters/Select';
import IndicatorMap from './map';
import ToggleFilter from '../Filters/Toggle';
import { propertyTipeActionValues, sectorActionValues } from './constants';
import './style.less';

//import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

export default function Indicators() {
  //const isDesktop = useMediaQuery({ minWidth: 1200 });
  //const forceGrid = useMediaQuery({ maxWidth: 767 });
  //const isMobile = useMediaQuery({ maxWidth: 480 });
  const indicators = [
    { name: 'Valor de venta en UF' },
    { name: 'Valor de arriendo' },
    { name: 'Volumen de oferta' },
    { name: 'Rentabilidad por m² útil' },
    { name: 'Valor de venta en UF/m²' },
    { name: 'Antiguedad promedio de contrución' },
    { name: 'Tiempo en el mercado' },
  ];
  var n = 0;

  //aca se hace la quiery
  const data = DATA;
  const [indicatorsList, setIndicatorsList] = useState(indicators);

  //Queries
  useQuery(GET_METRIC_TYPES, {
    onCompleted: (data) => {
      setIndicatorsList(data.getMetricTypes);
    },
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Valorization Failed:', error);
        if (error.graphQLErrors) {
          console.log(error.graphQLErrors);
        }
      }
    },
  });

  return (
    <div className="indicatorsView">
      <Layout>
        <Layout>
          <Sider width={340} theme={'light'} className="site-layout-background">
            <div className="tools">
              <div className="title">Indicadores de mercado</div>
            </div>
            <div className="description">
              <Tabs defaultActiveKey="1" centered={true}>
                <TabPane tab="Indicadores" keConty="1">
                  <span className="selected-zone">Santiago Centro, RM.</span>
                  <div className="indicators">
                    <Menu
                      mode="inline"
                      defaultSelectedKeys={['1']}
                      style={{ height: '100%', borderRight: 0 }}
                    >
                      {(indicatorsList.length != 0 ? indicatorsList : indicators).map(
                        (indicator) => {
                          n += 1;
                          return (
                            <Menu.Item
                              className="custom"
                              style={{ height: 50, 'line-height': 50 }}
                              key={n}
                              onClick={() => {}}
                            >
                              {indicator.name}
                            </Menu.Item>
                          );
                        },
                      )}
                    </Menu>
                  </div>
                </TabPane>
                <TabPane tab="Información del sector" key="2">
                  <span className="selected-zone">Santiago Centro, RM.</span>
                  <div className="information">Información importante</div>
                </TabPane>
              </Tabs>
            </div>
          </Sider>
          <Layout className="map-layout">
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                height: '100%',
              }}
            >
              <div className="bar-and-filters">
                <SearchDirectionBar />
                <SelectFilter
                  defaultValue={'comuna'}
                  options={sectorActionValues}
                  label="Tipo de sector"
                  callback={() => {
                    console.log(33);
                  }}
                />
                <ToggleFilter
                  defaultValue={'casa'}
                  options={propertyTipeActionValues}
                  callback={() => {
                    console.log(33);
                  }}
                />
              </div>
              <div className="map-container">
                <IndicatorMap data={data} />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}
