import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Marker, Popup } from 'react-map-gl';
import Card from '../Cards/Card';
import SkeletonCard from '../Cards/SkeletonCard';
import { GET_REAL_ESTATE_OFFER_PUBLICATIONS } from '../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { parsePropertiesAndMarkersData } from '../Properties/services';
import './style.less';
import Icon from '@ant-design/icons';
import { IconLock } from '../../assets';

const MapMarker = (props) => {
  MapMarker.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    operation: PropTypes.string.isRequired,
    id: PropTypes.number,
    creatingPolygone: PropTypes.bool,
    propertyType: PropTypes.string,
    forcePopup: PropTypes.bool,
    onClickPopup: PropTypes.func,
    handleSelectProperty: PropTypes.func,
    isSelected: PropTypes.bool,
    isBlocked: PropTypes.bool,
  };
  //

  MapMarker.defaultProps = {
    isBlocked: true,
  };

  const {
    id,
    latitude,
    operation,
    price,
    longitude,
    creatingPolygone,
    zoom,
    forcePopup,
    onClickPopup,
    handleSelectProperty,
    isSelected,
    isBlocked,
  } = props;

  const [onHover, setHover] = useState(false);
  const [cardData, setCardData] = useState();

  const [getCardData] = useLazyQuery(GET_REAL_ESTATE_OFFER_PUBLICATIONS, {
    variables: {
      ids: [id],
    },
    onCompleted: (data) => {
      const { parsedPropertiesData } = parsePropertiesAndMarkersData(
        data.getRealEstateOfferPublications,
      );
      setCardData(parsedPropertiesData[0]);
    },
  });
  useEffect(() => {
    if (forcePopup) {
      getCardData({
        variables: {
          ids: [id],
        },
      });
    }
  }, [forcePopup]);
  return (
    <div
      className="markerContainer"
      onMouseEnter={() => {
        setHover(true);
        getCardData({
          variables: {
            propertyIds: id,
          },
        });
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {(onHover || forcePopup) && !creatingPolygone ? (
        <Popup
          latitude={latitude}
          longitude={longitude}
          closeButton={false}
          closeOnClick={false}
          onClose={() => this.setState({ showPopup: false })}
          dynamicPosition={false}
          anchor="top"
        >
          {cardData ? (
            <Card
              onClick={() => onClickPopup({ ...cardData, longitude, latitude })}
              property={cardData}
              layout="simple"
              handleSelectProperty={handleSelectProperty}
              isSelected={isSelected}
              isBlocked={isBlocked}
            />
          ) : (
            <SkeletonCard layout="simple" />
          )}
        </Popup>
      ) : (
        <div />
      )}
      <Marker onHover setHover latitude={latitude} longitude={longitude}>
        {forcePopup || onHover ? (
          <div className="mapPin">
            <div className="mapPinDot mapPinDot--hover" />
          </div>
        ) : (
          <div className="mapPin">
            {zoom >= 16 ? (
              <div className="mapPinValue">
                {isBlocked ? (
                  <Icon component={IconLock} />
                ) : (
                  `UF ${
                    operation == 'sell'
                      ? price
                        ? Math.round(price).toLocaleString('de-DE')
                        : '-'
                      : price
                      ? Math.round(price).toLocaleString('de-DE') + ' /MES'
                      : '-'
                  }`
                )}
              </div>
            ) : (
              ''
            )}
            <div className="mapPinDot" />
          </div>
        )}
      </Marker>
    </div>
  );
};

export default MapMarker;
