import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import Icon from "@ant-design/icons";

import { IconClose } from "../../assets";
import "./style.less";

export default function CloseFilter(props) {
  CloseFilter.propTypes = {
    callback: PropTypes.func,
    params: PropTypes.object,
    label: PropTypes.string,
  };

  const { label, callback, params } = props;
  return (
    <Button
      size="small"
      style={{ borderRadius: 12 }}
      className="closeFilterButton"
      onClick={() => {
        callback(params);
      }}
    >
      {label}
      <Icon component={IconClose} />
    </Button>
  );
}
