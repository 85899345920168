import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { Slider as AntSlider, Popover, Button as AntButton } from "antd";
import Button from "../Buttons";
import Icon from "@ant-design/icons";

import { IconArrowDown } from "../../assets";
import "./style.less";

export default function SliderFilter(props) {
  const { title, label, callback } = props;
  const isWideScreen = useMediaQuery({ minWidth: 1366 });
  const antButtonSize = isWideScreen ? "large" : "middle";

  return (
    <div className="filterPopover">
      <Popover
        content={
          <SliderFilterContent
            callback={callback}
            title={title}
          ></SliderFilterContent>
        }
        trigger="hover"
        placement={"bottom"}
      >
        <AntButton type="default" size={antButtonSize}>
          {label}
          <Icon component={IconArrowDown} />
        </AntButton>
      </Popover>
    </div>
  );
}

SliderFilter.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func,
};

export function SliderFilterContent(props) {
  const { marks, callback, title } = props;
  const [minValue, setMinvalue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);

  const handleChange = (e) => {
    const newMaxValue = e[1] < 6 ? e[1] : undefined;

    setMinvalue(e[0]);
    setMaxValue(newMaxValue);
  };

  return (
    <div className="sliderFilterContent">
      <h3>{title}</h3>
      <SliderFilterInput marks={marks} onChange={handleChange} />
      <div className="button">
        <Button
          type="second"
          label={"Aplicar"}
          callback={callback ? callback : undefined}
          params={{ minValue, maxValue }}
        />
      </div>
    </div>
  );
}

SliderFilterContent.propTypes = {
  marks: PropTypes.objectOf(PropTypes.string),
  callback: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export const SliderFilterInput = ({ marks, onChange }) => {
  return (
    <div className="slider">
      <AntSlider
        range
        defaultValue={[0, 6]}
        min={0}
        max={6}
        onChange={onChange}
        marks={marks}
      />
    </div>
  );
};

SliderFilterInput.propTypes = {
  marks: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
};
SliderFilterInput.defaultProps = {
  marks: {
    0: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6+",
  },
};
