import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Select, Button, Spin } from 'antd';
import debounce from 'lodash/debounce';
import Icon from '@ant-design/icons';

import { IconSearchWhite, IconSearchPurple } from '../../assets';
import './style.less';

const { Option } = Select;

function URLify(string) {
  return string.trim().replace(/\s/g, '%20');
}

export default function SearchDirectionBarInMap(props) {
  SearchDirectionBarInMap.propTypes = {
    setAddress: PropTypes.func,
    addressName: PropTypes.string,
  };
  const { setAddress, addressName } = props;

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [state, setState] = useState({
    data: [],
    address: {},
    fetching: false,
  });

  useEffect(() => {
    if (addressName) {
      setState({
        data: [],
        address: { value: addressName },
        fetching: false,
      });
    }
  }, [addressName]);

  const [lastFetchId, setLastFetchId] = useState(0);

  const fetchUser = debounce((value) => {
    if (value == '') {
      return;
    }
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setState({ data: [], address: { value }, fetching: true });
    fetch(
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
        URLify(value) +
        '.json?types=address&access_token=' +
        process.env.REACT_APP_MAPBOX_ACCESS_TOKEN +
        '&country=cl',
    )
      .then((response) => response.json())
      .then((body) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return;
        }
        const data = body.features.map((address) => ({
          value: address.place_name,
          coords: {
            latitude: address.center[1],
            longitude: address.center[0],
          },
        }));
        setState((prevState) => {
          return { ...prevState, data, fetching: false };
        });
      });
  }, 800);

  const handleChange = (value) => {
    var result = state.data.filter((address) => {
      return address.value == value;
    });
    setState({
      data: [],
      address: result[0],
      fetching: false,
    });
    setAddress(result[0]);
  };

  const selectSize = isMobile ? 'middle' : 'large';

  return (
    <div className="select-direction-bar-on-map-container">
      <Select
        showSearch
        size={selectSize}
        showArrow={false}
        value={state.address.value}
        placeholder="Ingresa una dirección"
        notFoundContent={state.fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={fetchUser}
        onChange={handleChange}
        style={{ width: '100%' }}
      >
        {state.data.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
      <Button
        className="search-button"
        icon={<Icon component={isMobile ? IconSearchPurple : IconSearchWhite} />}
      />
    </div>
  );
}
