import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { fakeData, fakeCollections } from '../../fakeData';
import { PropertyCard } from '../Cards/PropertyCard';
import PropertiesExcelDownload from '../Properties/ExcelDownload';
import { Button, Tooltip, Modal, Input } from 'antd';
import Icon from '@ant-design/icons';
import {
  IconClose,
  IconDislike,
  IconDownload,
  IconPlusNew,
  IconReload,
  IconSaved,
} from '../../assets';

import Constants from '../../constants';

const { IS_MOBILE } = Constants;

export const UserProfileFavorites = () => {
  const [favorites, setFavorites] = useState(fakeData);
  const [selectedFavorites, setSelectedFavorites] = useState(new Set());
  const [collectionModalIsVisible, setCollectionModalIsVisible] = useState(false);
  const [newCollectionModalIsVisible, setNewCollectionModalIsVisible] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [addedCollectionTooltip, setAddedCollectionTooltip] = useState(false);

  const isMobile = useMediaQuery(IS_MOBILE);

  const handleSelectedFavorites = (id, isSelected) => {
    const newSet = new Set(selectedFavorites);
    if (isSelected) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedFavorites(newSet);
  };

  const resetSelectedFavorites = () => {
    setSelectedFavorites(new Set());
  };

  const unlikeSelectedFavorites = () => {
    const newFavorites = favorites.filter((favorite) => !selectedFavorites.has(favorite.id));
    setFavorites(newFavorites);
    resetSelectedFavorites();
  };

  const unlikeIndividualFavorite = (id) => {
    if (selectedFavorites.has(id)) handleSelectedFavorites(id, false);
    const newFavorites = favorites.filter((favorite) => favorite.id !== id);
    setFavorites(newFavorites);
  };

  const showCollectionModal = () => {
    setCollectionModalIsVisible(true);
  };

  const showNewCollectionModal = () => {
    setNewCollectionModalIsVisible(true);
  };

  const handleInputChange = (e) => {
    setNewCollectionName(e.target.value);
  };

  return (
    <div className="favorites-data-view">
      <Modal
        visible={collectionModalIsVisible && selectedFavorites.size > 0}
        onCancel={() => setCollectionModalIsVisible(false)}
        title={[
          <div key="1" className="text">
            Guardar en colección:{' '}
          </div>,
          <div key="2" className="plus-button">
            <Button
              icon={<Icon component={IconPlusNew} />}
              type="link"
              onClick={() => {
                setCollectionModalIsVisible(false);
                showNewCollectionModal();
              }}
            />
          </div>,
        ]}
        footer={[
          <Button
            key="back"
            className="footer-button"
            onClick={() => setCollectionModalIsVisible(false)}
          >
            Cancelar
          </Button>,
        ]}
        closable={false}
        className="collection-modal"
        width={785}
        height={338}
        destroyOnClose
        centered
      >
        <div className="row-display">
          {fakeCollections.map((collection) => {
            return (
              <div key={collection.id} className="display-element">
                <div className="display-element-img">
                  <img
                    src={`${collection.img}`}
                    alt="foto"
                    onClick={() => {
                      setCollectionModalIsVisible(false);
                      setAddedCollectionTooltip({
                        show: true,
                        number: selectedFavorites.size,
                        name: collection.name,
                      });
                      setTimeout(
                        () => setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false }),
                        7000,
                      );
                    }}
                  />
                </div>
                <div className="text">{collection.name}</div>
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        visible={newCollectionModalIsVisible}
        onCancel={() => setNewCollectionModalIsVisible(false)}
        title={[
          <div key="1" className="text">
            Nueva colección
          </div>,
        ]}
        footer={[
          <div key="1" className="footer-button">
            {newCollectionName === '' ? (
              <Button
                className="footer-button"
                key="back"
                onClick={() => setNewCollectionModalIsVisible(false)}
              >
                Cancelar
              </Button>
            ) : (
              <Button
                className="footer-button"
                key="submit"
                onClick={() => {
                  const name = newCollectionName;
                  setNewCollectionName('');
                  setNewCollectionModalIsVisible(false);
                  setAddedCollectionTooltip({
                    show: true,
                    number: selectedFavorites.size,
                    name: name,
                  });
                  setTimeout(
                    () => setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false }),
                    7000,
                  );
                }}
              >
                Listo
              </Button>
            )}
          </div>,
        ]}
        closable={false}
        className="collection-modal"
        width={785}
        height={334}
        destroyOnClose
        centered
      >
        <div className="new-collection-body">
          <div className="display-element">
            <div className="display-element-img">
              <img src={`${fakeCollections[0].img}`} alt="foto" />
            </div>
            <div className="text">
              <Input className="new-input" bordered={false} onChange={handleInputChange} />
            </div>
          </div>
        </div>
      </Modal>

      {addedCollectionTooltip.show && (
        <div className="add-collections-popup">
          <div className="add-popup-text">
            {`${addedCollectionTooltip.number} propiedades han sido guardadas en la colección "${addedCollectionTooltip.name}"`}
          </div>
          <div className="add-popup-undo" onClick={() => {}}>
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </div>
        </div>
      )}

      {isMobile ? (
        <div className="favorites-tag">
          <div className="text"> Mis favoritos</div>
        </div>
      ) : (
        ''
      )}
      <div className="favorites-data-view-header">
        <div className="favorites-data-view-header-count">
          {`${favorites.length} ${!isMobile ? 'propiedades favoritas' : 'favoritos'}`}
        </div>
        <div className="favorites-data-view-header-options">
          <div className="text">
            {`${selectedFavorites.size} ${
              !isMobile ? 'propiedades seleccionadas' : 'seleccionados'
            }`}
          </div>
          <div className="actions">
            <span className="favorites-header-tools-first">
              <Tooltip title="Agregar a colección">
                <Button
                  icon={<Icon component={IconSaved} />}
                  type="link"
                  onClick={() => showCollectionModal()}
                />
              </Tooltip>
            </span>
            <span className="favorites-header-tools">
              <Tooltip title="Eliminar favoritos">
                <Button
                  icon={<Icon component={IconDislike} />}
                  type="link"
                  onClick={unlikeSelectedFavorites}
                />
              </Tooltip>
            </span>
            <span className="favorites-header-tools">
              <PropertiesExcelDownload
                render={
                  <Tooltip title="Descargar Excel">
                    <Button
                      icon={<Icon component={IconDownload} />}
                      type="link"
                      onClick={() => {}}
                    />
                  </Tooltip>
                }
                filename="TassApp Resumen de Propiedades"
                sheetName="Hoja 1"
                data={[]}
              ></PropertiesExcelDownload>
            </span>
            <span className="favorites-header-tools">
              <Tooltip title="Deseleccionar" placement="topRight">
                <Button
                  icon={<Icon component={IconClose} />}
                  type="link"
                  onClick={() => resetSelectedFavorites()}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      {favorites.map((property) => {
        return (
          <PropertyCard
            key={property.id}
            property={property}
            handleSelectedFavorite={handleSelectedFavorites}
            isSelected={selectedFavorites.has(property.id)}
            unlikeIndividualFavorite={unlikeIndividualFavorite}
          />
        );
      })}
    </div>
  );
};

export default UserProfileFavorites;
