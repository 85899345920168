import React, { useState } from "react";
import PropTypes from "prop-types";

export default function FeatureCard(props) {
  FeatureCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };
  const [onHoverState, setOnHoverState] = useState(false);

  const { image, title, text } = props;

  return (
    <div
      className="feature-card"
      onMouseEnter={() => setOnHoverState(true)}
      onMouseLeave={() => setOnHoverState(false)}
    >
      <img src={image} alt="" />
      {onHoverState ? <p>{text}</p> : <h4>{title}</h4>}
    </div>
  );
}
