import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  { label: "Dirección", value: "address" },
  { label: "Tipo Propiedad", value: "propertyType" },
  { label: "Precio venta", value: "sellPrice" },
  { label: "Precio arriendo", value: "rentPrice" },
  { label: "Área cubierta", value: "coveredArea" },
  { label: "Área total", value: "totalArea" },
  { label: "Dormitorios", value: "bedRooms" },
  { label: "Baños", value: "fullBathrooms" },
  { label: "Latitud", value: "latitude" },
  { label: "Longitude", value: "longitude" },
  {
    label: "Valorización de venta",
    value: "sellValorization",
  },
  {
    label: "Valorización de arriendo",
    value: "rentValorization",
  },
];

export default function PropertiesExcelDownload(props) {
  PropertiesExcelDownload.propTypes = {
    filename: PropTypes.string.isRequired,
    sheetName: PropTypes.string.isRequired,
    render: PropTypes.element.isRequired,
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  };

  const { filename, sheetName, render, data } = props;

  return (
    <ExcelFile
      filename={filename ? filename : "TassApp-DownloadFile"}
      element={render ? render : null}
    >
      {data && columns ? (
        <ExcelSheet data={data} name={sheetName ? sheetName : "Sheet 1"}>
          {columns.map((column) => (
            <ExcelColumn
              key={column.label}
              label={column.label}
              value={column.value}
            />
          ))}
        </ExcelSheet>
      ) : (
        <></>
      )}
    </ExcelFile>
  );
}
