import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.less';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Icon from '@ant-design/icons';
import {
  ArrowLeftShadow,
  ArrowRightShadow,
  likeSelected,
  likeUnselected,
  likeUnselectedLavander,
  likeSelectedLavander,
  noPictureAvailable,
} from '../../assets';
import moment from 'moment';
import 'moment/locale/es';
import Constants from '../../constants';
import { useMediaQuery } from 'react-responsive';

moment.locale('es');

const { IS_MOBILE } = Constants;

export default function ImageCarousel({
  property,
  handleSelectedFavorite,
  isSelected,
  unlikeIndividualFavorite,
}) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [liked, setLiked] = useState(true);
  const [theme, setTheme] = useState(false);

  const isMobile = useMediaQuery(IS_MOBILE);

  useEffect(() => {
    const img = new Image();
    img.src = property.pictures[photoIndex];
    if (property.pictures.length === 0) {
      setTheme(false);
    } else if (img.width >= img.height - 100) {
      setTheme(true);
    } else {
      setTheme(false);
    }
  }, [photoIndex]);

  const handleLike = () => {
    setLiked(!liked);
    unlikeIndividualFavorite(property.id);
  };

  function changePhotoIndex(value) {
    const index = photoIndex + value;
    if (index < 0) {
      setPhotoIndex(property.pictures.length - 1);
    } else if (index >= property.pictures.length) {
      setPhotoIndex(0);
    } else {
      setPhotoIndex(index);
    }
  }

  return (
    <div
      //className={theme ? "cardCoverFit" : "cardCover"}
      style={{
        backgroundImage: `url(${property.pictures[photoIndex] || noPictureAvailable})`,
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className="cardCoverIcons">
        <Checkbox
          style={{ borderColor: '#FFFFF', backgroundColor: '#FFFFF', opacity: 0.76 }}
          onChange={(e) => handleSelectedFavorite(property.id, e.target.checked)}
          onClick={(e) => e.stopPropagation()}
          checked={isSelected}
        />
        <div className="coverIcon" onClick={handleLike}>
          {liked ? (
            <img src={theme ? likeSelected : likeSelectedLavander} alt="like" />
          ) : (
            <img src={theme ? likeUnselected : likeUnselectedLavander} alt="like" />
          )}
        </div>
      </div>
      <div className="cardCoverImageSelector">
        {property.pictures.length > 1 && !isMobile && (
          <>
            <div
              className="arrowSelector"
              onClick={(e) => {
                e.stopPropagation();
                changePhotoIndex(-1);
              }}
            >
              <Icon component={ArrowLeftShadow} />
            </div>
            <div
              className="arrowSelector"
              onClick={(e) => {
                e.stopPropagation();
                changePhotoIndex(1);
              }}
            >
              <Icon component={ArrowRightShadow} height="2em" width="2em" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ImageCarousel.defaultProps = {
  handleSelectedFavorite: () => {},
  isSelected: false,
  unlikeIndividualFavorite: () => {},
};

ImageCarousel.propTypes = {
  property: PropTypes.shape({
    propertyType: PropTypes.string,
    id: PropTypes.int,
    liked: PropTypes.bool,
    lenght: PropTypes.number,
    operation: PropTypes.string,
    sellPrice: PropTypes.number,
    pictures: PropTypes.array,
    publicationDate: PropTypes.instanceOf(Date),
  }).isRequired,
  handleSelectedFavorite: PropTypes.func,
  isSelected: PropTypes.bool,
  unlikeIndividualFavorite: PropTypes.func,
};
