import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Switch, Route } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import UserProfileData from './Data';
import MassiveAppraisals from './MassiveAppraisals';
import UserProfileSideMenu from './SideMenu';
import UserProfileAppraisals from './Appraisals';
import UserProfileSelectedCollection from './SpecificCollection';
import { UserProfileFavorites } from './Favorites';
import { UserProfileCollections } from './Collections';
import Loading from '../Loading';
import { userRoleVar } from '../../graphql/cache';
import { GET_CURRENT_USER_DATA } from '../../graphql/queries';
import './style.less';

export default function UserProfile(props) {
  const { userToken } = props;
  const [userName, setUserName] = useState();
  const [lastName, setLastName] = useState();
  const [userRole, setUserRole] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const { loading } = useQuery(GET_CURRENT_USER_DATA, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      userRoleVar(data.getCurrentUser.role.name);
      setUserName(data.getCurrentUser.firstName);
      setLastName(data.getCurrentUser.lastName);
      setUserRole(data.getCurrentUser.role.name);
      setUserEmail(data.getCurrentUser.email);
      setUserPhone(data.getCurrentUser.phone);
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === 'development') {
        if (graphQLErrors) {
          console.log('getCurrentUser', graphQLErrors);
        }
        if (networkError) {
          console.log('getCurrentUser', networkError);
        }
      }
    },
  });

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return !loading && userName ? (
    <div className="user-profile-view">
      {isDesktop && (
        <UserProfileSideMenu
          title={`Perfil de ${userName || ''} ${lastName || ''}`.toUpperCase()}
        />
      )}
      <Switch>
        <Route
          path="/user/massive-appraisals"
          render={(params) => <MassiveAppraisals {...params} />}
        />
        <Route
          path="/user/appraisals"
          render={(params) => <UserProfileAppraisals userToken={userToken} {...params} />}
        />
        <Route
          path="/user/profile"
          render={(params) => (
            <UserProfileData
              {...params}
              userName={userName}
              lastName={lastName}
              userRole={userRole}
              userEmail={userEmail}
              userPhone={userPhone}
            />
          )}
        />
        <Route
          path="/user/favorites"
          render={(params) => <UserProfileFavorites userToken={userToken} {...params} />}
        />
        <Route
          path="/user/collections"
          render={(params) => <UserProfileCollections userToken={userToken} {...params} />}
        />
        <Route path="/user/collection/:id" render={() => <UserProfileSelectedCollection />} />
      </Switch>
    </div>
  ) : (
    <Loading />
  );
}

UserProfile.propTypes = {
  userToken: PropTypes.string.isRequired,
};
