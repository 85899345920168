import React from "react";
import PropTypes from "prop-types";
import { Marker } from "react-map-gl";
import "./style.less";

const MainMarker = (props) => {
  MainMarker.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    isSatelital: PropTypes.bool,
  };

  const { latitude, longitude, isSatelital } = props;

  return (
    <Marker latitude={latitude} longitude={longitude}>
      <div className="mapPin">
        {isSatelital ? (
          <div className="mapMainPin mapMainSatelitalPin" />
        ) : (
          <div className="mapMainPin mapMainDefaultPin " />
        )}
      </div>
    </Marker>
  );
};

export default MainMarker;
