import { gql } from '@apollo/client';

const GET_METRIC_TYPES = gql`
  query getMetricTypes {
    getMetricTypes {
      id
      category
      name
      unit
    }
  }
`;

const GET_REAL_ESTATE_OFFER_PUBLICATIONS = gql`
  query getRealEstateOfferPublications($ids: [Int!]) {
    getRealEstateOfferPublications(ids: $ids) {
      id
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
    }
  }
`;

const GET_PROPERTIES_FROM_FILTER = gql`
  query getPropertiesFromFilter(
    $polygon: JSON
    $property_type: String
    $operation_type: String
    $published_price__min: Float
    $published_price__max: Float
    $rooms__min: Int
    $rooms__max: Int
    $bathrooms__min: Int
    $bathrooms__max: Int
    $covered_area__min: Float
    $covered_area__max: Float
    $total_area__min: Float
    $total_area__max: Float
  ) {
    getPropertiesFromFilter(
      polygon: $polygon
      property_type: $property_type
      operation_type: $operation_type
      published_price__min: $published_price__min
      published_price__max: $published_price__max
      rooms__min: $rooms__min
      rooms__max: $rooms__max
      bathrooms__min: $bathrooms__min
      bathrooms__max: $bathrooms__max
      covered_area__min: $covered_area__min
      covered_area__max: $covered_area__max
      total_area__min: $total_area__min
      total_area__max: $total_area__max
    ) {
      id
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
    }
  }
`;

const LOCATE_MUNICIPALITY = gql`
  query getMunicipality($latitude: Float!, $longitude: Float!) {
    getMunicipality(latitude: $latitude, longitude: $longitude) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

const LOCATE_CITY = gql`
  query locateCity($latitude: Float!, $longitude: Float!) {
    locateCity(latitude: $latitude, longitude: $longitude) {
      id
      name
      state {
        id
        name
      }
    }
  }
`;

const GET_CURRENT_USER_DATA = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      email
      phone
      role {
        id
        name
      }
    }
  }
`;

const GET_ID_USER_DATA = gql`
  query getUser($id: Int!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      phone
      role {
        id
        name
      }
    }
  }
`;

const GET_CURRENT_USER_VALORIZATIONS = gql`
  query getMyValorizations($limit: Int!, $offset: Int!) {
    getMyValorizations(limit: $limit, offset: $offset) {
      id
      address
      propertyType
      createdAt
      sellPrice
      rentPrice
      longitude
      latitude
    }
  }
`;
const GET_CURRENT_USER_MASSIVE_VALORIZATIONS = gql`
  query getMyMassiveValorizations($limit: Int!, $offset: Int!) {
    getMyMassiveValorizations(limit: $limit, offset: $offset) {
      id
      name
      valorizationCount
      createdAt
    }
  }
`;
const GET_VALORIZATION = gql`
  query getValorization($id: Int!, $shareToken: String) {
    getValorization(id: $id, shareToken: $shareToken) {
      id
      sellPrice
      rentPrice
      address
      latitude
      longitude
      lowerBoundSellCI
      upperBoundSellCI
      lowerBoundRentCI
      upperBoundRentCI
      propertyType
      hasSwimmingPool
      totalArea
      coveredArea
      parkingLots
      bathrooms
      bedrooms
      floors
      yield
      upperBoundYieldCI
      lowerBoundYieldCI
      warehouses
      hasBalcony
    }
  }
`;

const GET_MASSIVE_VALORIZATION = gql`
  query getMassiveValorization($id: Int!) {
    getMassiveValorization(id: $id) {
      name
      createdAt
    }
  }
`;

const GET_MASSIVE_VALORIZATION_DETAILS = gql`
  query getMassiveValorizationDetail($limit: Int!, $offset: Int!, $id: Int!) {
    getMassiveValorizationDetail(limit: $limit, offset: $offset, id: $id) {
      id
      sellPrice
      rentPrice
      address
      propertyType
      latitude
      longitude
    }
  }
`;
const GET_USERS = gql`
  query getUsers(
    $textSearch: NonEmptyString
    $roleId: Int
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    getUsers(textSearch: $textSearch, roleId: $roleId, fromDate: $fromDate, toDate: $toDate) {
      id
      lastName
      firstName
      email
      phone
      active
      createdAt
      role {
        id
        name
      }
    }
  }
`;

const GET_ROLES = gql`
  query getRoles($active: Boolean!) {
    getRoles(active: $active) {
      id
      spanishName
    }
  }
`;

export {
  GET_PROPERTIES_FROM_FILTER,
  LOCATE_CITY,
  LOCATE_MUNICIPALITY,
  GET_VALORIZATION,
  GET_MASSIVE_VALORIZATION,
  GET_MASSIVE_VALORIZATION_DETAILS,
  GET_METRIC_TYPES,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_VALORIZATIONS,
  GET_CURRENT_USER_MASSIVE_VALORIZATIONS,
  GET_USERS,
  GET_ROLES,
  GET_REAL_ESTATE_OFFER_PUBLICATIONS,
  GET_ID_USER_DATA,
};
